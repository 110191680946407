import PropTypes from 'prop-types';
import { isEmpty, isFunction } from 'lodash-es';
import ColorBatch from '../../ColorBatch';
import { Icon } from '../../Icon';
import {
  dropDownSelect,
  dropDownRightIcon,
  dropDownText,
  rightIconsContainer,
  dropDownDeleteIcon,
  dropDownItemsCountBadge,
} from './styles';

const DropdownSelect = ({
  leftIcon,
  rightIcon,
  small = false,
  multiSelect = false,
  noClear = false,
  noItemsBadge = false,
  selected,
  handleSelect,
  isOpen,
  isDisabled,
  displayValue,
  hasError,
}) => {
  const clearIcon = !noClear && (
    <Icon
      material
      iconName="close"
      color="black"
      css={dropDownDeleteIcon}
      onClick={(e) => {
        e.stopPropagation();
        handleSelect(null);
      }}
    />
  );

  const itemsBadge = !noItemsBadge && <ColorBatch css={dropDownItemsCountBadge(false)}>{selected?.length}</ColorBatch>;

  return (
    <div css={dropDownSelect(isOpen, isDisabled, hasError, small)} className="select-dropdown">
      {leftIcon?.iconName ? <Icon {...leftIcon} /> : isFunction(leftIcon) ? leftIcon(isOpen) : leftIcon}
      <span css={dropDownText}>{displayValue}</span>
      <div css={rightIconsContainer}>
        {!isEmpty(selected) && (multiSelect ? itemsBadge : clearIcon)}
        <Icon material iconName="expand_more" color="black" css={dropDownRightIcon(isOpen)} {...rightIcon} />
      </div>
    </div>
  );
};

DropdownSelect.propTypes = {
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.object,
  small: PropTypes.bool,
  multiSelect: PropTypes.bool,
  noClear: PropTypes.bool,
  noItemsBadge: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleSelect: PropTypes.func,
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  displayValue: PropTypes.any,
};

export default DropdownSelect;
