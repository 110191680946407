import { getText } from '@veraio/strank';
import {
  // REMOVE TAG HIDE FOR FIRST VERSION
  // Button,
  ColorBatch,
} from 'components';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { ROUTES } from 'enums';
import { affiliateContainer } from './styles';

const BecomeAffiliateCart = () => (
  <div css={affiliateContainer}>
    <div className="container">
      <p className="upper-text">{getText('unlockExclusivePerks')}</p>
      <h4 className="affiliate-title">{getText('becomeAnAffiliateOfOneVita')}</h4>
      <ColorBatch type="default" className="batch">
        {getText('comingSoon')}
      </ColorBatch>
      {/* // REMOVE TAG HIDE FOR FIRST VERSION
      <Button type="secondary" className="affiliate-button" linkTo={ROUTES.BecomeAnAffiliate}>
        {getText('learnHow')}
      </Button> */}
    </div>
  </div>
);

export default BecomeAffiliateCart;
