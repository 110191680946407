import { theme } from 'styles';

export const dotsContainer = {
  transform: 'translateX(0%)',
  overflow: 'hidden',
  width: '100%',
  height: 'auto',

  '.dots-list': {
    display: 'flex',
    flexWrap: 'nowrap',
    listStyle: 'none',
    transition: 'transform 0.45s linear',
  },
};

export const indicatorDot = (isActive) => [
  {
    border: isActive && `2px solid ${theme.primary}`,
    width: 100,
    minWidth: 100,
    flex: '0 0 100px',
    height: 60,
    margin: 4,
    cursor: 'pointer',
    transition: 'background-color 0.45s ease-in',
  },
];
