import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  useTranslations,
  getSavedLanguage,
  getBrowserLanguage,
  initTranslationsStore,
  initLanguagesStore,
} from '@veraio/strank';
import { initCurrenciesStore, initLocationsStore } from '@oneecosystem/dealshaker-core';
import { NavigationProvider, Notifications, useDeepEffect } from 'components';
import {
  initCountries,
  // useUserStore
} from 'stores';
// import { ForgotPassword, Login } from './authorization';
// import { ROUTES } from 'enums';
import { Routes } from './Routes';

const App = () => {
  const { changeLanguage, allEnvLanguages } = useTranslations();
  // const isAuthenticated = useUserStore((userState) => userState.isAuthenticated);

  useDeepEffect(() => {
    allEnvLanguages?.length && changeLanguage(getSavedLanguage() ?? getBrowserLanguage());
  }, [allEnvLanguages]);

  useEffect(() => {
    initCountries();
    initCurrenciesStore();
    initLanguagesStore();
    initLocationsStore();
    initTranslationsStore();
  }, []);

  return (
    <NavigationProvider>
      <Notifications />
      <Switch>
        {/* REMOVE TAG HIDE FOR FIRST VERSION
        {!isAuthenticated && <Route exact path={ROUTES.Login} component={Login} />} */}
        {/* {!isAuthenticated && <Route exact path={ROUTES.ForgotPassword} component={ForgotPassword} />} */}
        <Route component={Routes} />
      </Switch>
    </NavigationProvider>
  );
};

export default App;
