import { isNull } from 'lodash-es';

export const formatShortText = (val, textLength) => {
  if (isNull(val) || val === '' || val === undefined) return '';
  const formatedText = val.replace(/(<([^>]+)>)/gi, '');
  const textLenghtVal = textLength ?? 200;
  return formatedText.length > textLenghtVal
    ? `${formatedText.substring(0, textLenghtVal)}...`
    : formatedText;
};
