import { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const NavigationContext = createContext({});
export const useNavigationControls = () => useContext(NavigationContext);

export const NavigationProvider = (props) => {
  const { children } = props;
  const [sideNavControls, setSideNavControls] = useState({});
  const [allRoutes, setAllRoutes] = useState([]);

  return (
    <NavigationContext.Provider
      value={{
        ...sideNavControls,
        setupSideNavControls: (api) => setSideNavControls(api),
        allRoutes,
        changeAllRoutes: setAllRoutes,
      }}>
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: PropTypes.node,
};
