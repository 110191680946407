export const ingredientsData = [
  {
    id: 1,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/flower-icon.png',
    title: 'liposomes',
  },
  {
    id: 2,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/melatonin-icon.png',
    title: 'melatonin',
  },
  {
    id: 3,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cinnamon-icon.png',
    title: 'cinnamon',
  },
  {
    id: 4,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
    title: 'qTen',
  },
  {
    id: 5,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitaminC-icon.png',
    title: 'vitaminC',
  },
  {
    id: 6,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/retinol-icon.png',
    title: 'retinol',
  },
  {
    id: 7,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/zeolites-icon.png',
    title: 'zeolites',
  },
  {
    id: 8,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
    title: 'caffeine',
  },
];
