import { theme } from 'styles';

export const footerContainer = (isMobile) => ({
  padding: '3em 5em 0',
  backgroundColor: theme.secondaryLighter,
  position: isMobile && 'absolute',
  bottom: isMobile && 0,
  justifyContent: 'space-between',

  '.footer-label': {
    fontWeight: 700,
    fontSize: 15,
    color: theme.blackLightDark,
    textTransform: 'uppercase',
    margin: '16px 0',
  },

  '.products-footer-container': {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.gray200}`,
    paddingBottom: '1em',

    '.footer-link-button': {
      fontWeight: 400,
      fontSize: 16,
      color: theme.black,
      textTransform: 'uppercase',
      padding: '0.5em 0em',

      '&:active': { outline: 'none' },
    },

    '.touch-text': {
      fontWeight: 500,
      fontSize: 17,
      color: theme.black,
    },

    '.online-container': {
      display: 'flex',
      flexDirection: 'column',

      '.online-description': {
        fontWeight: 400,
        fontSize: isMobile ? 14 : 16,
        color: theme.gray300,
        marginBottom: 24,
      },

      '.social-container': {
        display: 'flex',
        flexDirection: 'row',
      },
    },

    '@media (max-width: 576px)': {
      flexWrap: 'wrap',
    },
  },

  '.footer-container': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',

    '.logo-country-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '.logo-container': {
        overflowX: 'hidden',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        minWidth: '4.8rem',
        marginRight: 32,

        '.logo': {
          width: 100,
          height: 48,
          minWidth: '4.8rem',
          opacity: '80%',
          margin: '16px 0',

          '@media (max-width: 576px)': {
            width: 133,
          },
        },
      },

      '.dropdown': {
        width: 135,
        display: 'flex',
      },

      '@media (max-width: 576px)': {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: 16,
        width: '100%',
      },

      '@media (max-width: 992px)': {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '100%',
      },
    },

    '.terms-rights-container': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',

      '.text': {
        fontSize: 14,
        color: theme.gray300,
        fontWeight: 400,
      },

      '.button': {
        fontWeight: 500,
        fontSize: 14,
        color: theme.black,
        padding: '0px 8px',

        '&:active': { outline: 'none' },
      },
    },
  },

  '@media (max-width: 576px)': {
    padding: 24,
  },
});

export const modalContainer = {
  padding: 20,
  display: 'flex',
  flexDirection: 'row',

  '.title': {
    color: theme.black,
  },

  '.scrollable-container': {
    height: 500,
    overflow: 'auto',

    '.more-info': {
      fontSize: 16,
      fontWeight: 300,
      color: theme.gray300,
      lineHeight: 1.4,
    },
  },

  '.scrollable-container::-webkit-scrollbar': {
    display: 'none',
  },

  '.horizontal-line': {
    backgroundColor: theme.gray200,
  },
};
