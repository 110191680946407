import { isFunction } from 'lodash-es';
import { deepEqual } from './lodash';

export const createStore = stateInitialValue => {
  let state;
  const listeners = new Set();

  const setState = newState => {
    const nextState = isFunction(newState) ? newState(state) : newState;
    if (!deepEqual(nextState, state)) {
      const oldState = { state };
      state = nextState;
      listeners.forEach(listener => isFunction(listener) && listener(nextState, oldState.state));
    }
  };

  const getState = () => state;

  const subscribe = listener => {
    listeners.add(listener);
    // Unsubscribe
    return () => listeners.delete(listener);
  };

  const destroy = () => listeners.clear();
  const api = { setState, getState, subscribe, destroy };
  state = isFunction(stateInitialValue) ? stateInitialValue(api) : stateInitialValue;

  return api;
};
