import { theme } from 'styles';

export const ingredientsContainer = {
  position: 'relative',

  '.header-image': {
    width: '100%',
    maxHeight: 580,
    objectPosition: '0% 100%',
    zIndex: 100,

    '@media (max-width: 767px)': {
      height: 600,
      width: '100%',
    },

    '@media (max-width: 576px)': {
      maxHeight: 700,
      height: 700,
    },
  },

  '.header-content-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 50,
    width: '100%',
    zIndex: 1,
    padding: '0 2em',

    '.header-title': {
      fontSize: 48,
      fontWeight: 400,
      color: theme.black,
      marginBottom: 12,
    },

    '.header-description': {
      fontSize: 20,
      fontWeight: 300,
      color: theme.gray800,
      maxWidth: 650,
      textAlign: 'center',

      '@media (max-width: 767px)': {
        textAlign: 'left',
      },

      '@media (max-width: 576px)': {
        textAlign: 'center',
      },
    },

    '@media (max-width: 992px)': {
      top: 30,
    },

    '@media (max-width: 767px)': {
      paddingTop: '4em',
    },

    '@media (max-width: 576px)': {
      textAlign: 'center',
    },
  },

  '.ingredients-content': {
    padding: '6em 11.25em',
    backgroundColor: theme.secondaryLight,

    '.title': {
      fontSize: 48,
      fontWeight: 400,
      color: theme.black,
      marginBottom: 40,
      textTransform: 'uppercase',
    },

    '.liposomes-image': {
      width: '45%',
      marginRight: 80,

      '@media (max-width: 992px)': {
        width: '100%',
        marginRight: 0,
      },
    },

    '.liposomes-container': {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 40,

      '.liposomes-content-container': {
        paddingTop: '2em',

        '.liposomes-title': {
          fontSize: 28,
          lineHeight: 1.3,
          color: theme.gray900,
          marginBottom: 24,
        },

        '.liposomes-description': {
          fontSize: 16,
          fontWeight: 300,
          lineHeight: 1.5,
          color: theme.gray300,
        },
      },

      '@media (max-width: 992px)': {
        flexWrap: 'wrap',
      },
    },

    '.liposome-future-container': {
      marginBottom: 40,

      '.liposome-future-image': {
        width: 60,
        height: 60,
        marginBottom: 24,
      },

      '.liposome-future-title': {
        fontSize: 40,
        fontWeight: 400,
        color: theme.black,
        marginBottom: 24,
      },

      '.liposome-future-description': {
        fontSize: 16,
        fontWeight: 300,
        lineHeight: 1.5,
        color: theme.gray300,
      },
    },

    '@media (max-width: 992px)': {
      padding: '3em 6em',
    },

    '@media (max-width: 767px)': {
      padding: '3em 2em',
    },
  },

  '.other-ingredients-container': {
    padding: '6em 11.25em',
    backgroundColor: theme.secondaryLighter,
    marginBottom: 40,

    '.other-title': {
      fontSize: 40,
      fontWeight: 400,
      color: theme.black,
      marginBottom: 32,
    },

    '.other-ingredients-cart': {
      backgroundColor: theme.white,
      boxShadow: theme.boxShadow,
      padding: '3em',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',

      '.other-ingredients-image': {
        width: 60,
        height: 60,
        marginBottom: 16,
      },

      '.other-ingredients-title': {
        fontSize: 32,
        fontWeight: 400,
        color: theme.black,
        marginBottom: 16,
      },

      '.other-ingredients-description': {
        fontSize: 15,
        fontWeight: 300,
        lineHeight: 1.5,
        color: theme.gray300,
      },
    },

    '@media (max-width: 992px)': {
      padding: '3em 6em',
    },

    '@media (max-width: 767px)': {
      padding: '3em 2em',
    },
  },

  '.discover-container': {
    padding: '0em 11.25em ',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',

    '.action-button': {
      textTransform: 'uppercase',
      marginBottom: 36,
      backgroundColor: 'transparent',
      color: theme.gray500,
      border: `1px solid ${theme.grayLight}`,
      fontSize: 16,
      fontWeight: 400,
      width: 'fit-content',

      '&:active': { outline: 'none' },

      '@media (max-width: 767px)': {
        width: '100%',
      },
    },

    '.discover-beauty-title': {
      fontSize: 38,
      fontWeight: 400,
      color: theme.black,
      marginBottom: 16,
    },

    '.discover-beauty-subtitle': {
      fontSize: 18,
      fontWeight: 300,
      color: theme.gray900,
      lineHeight: 1.5,
      marginBottom: 16,
    },

    '@media (max-width: 767px)': {
      maxWidth: '100%',
      padding: '0em 3em ',
    },
  },

  '.discover-image': {
    width: '100%',
  },
};
