export const liposomesData = [
  {
    id: 1,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/flower-icon.png',
    title: 'liposomesAreCarriersOfYouth',
    description: 'theyDeliverToSkinCells',
  },
  {
    id: 2,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/flower-icon.png',
    title: 'liposomesAreFutureOfCosmetics',
    description: 'theyAreNearFuture',
  },
];

export const liposomalIngredientsData = [
  {
    id: 1,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/flower-icon.png',
    title: 'liposomes',
    description: 'harnessPowerOfLiposomeTechnology',
  },
  {
    id: 2,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitaminC-icon.png',
    title: 'vitaminC',
    description: 'vitaminCBestSkincareIngredient',
  },
  {
    id: 3,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cinnamon-icon.png',
    title: 'cinnamon',
    description: 'liposomeCinnamonNotJustSweet',
  },
  {
    id: 4,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/melatonin-icon.png',
    title: 'melatonin',
    description: 'protectAndRefreshYourSkinWithMelatonin',
  },
  {
    id: 5,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/retinol-icon.png',
    title: 'retinol',
    description: 'transformYourSkinByRenewingCells',
  },
  {
    id: 6,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
    title: 'qTen',
    description: 'experienceLifeChangingHealthAndBeautyBoosts',
  },
  {
    id: 7,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
    title: 'caffeine',
    description: 'energizeYourSkinToNewHorizonsWithCaffeine',
  },
  {
    id: 2,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/zeolites-icon.png',
    title: 'zeolites',
    description: 'turnBackClockOnAgingAndExperience',
  },
];
