import { theme } from 'styles';

export const backButtonContainer = {
  padding: '1em 0',
  color: theme.blue,
  display: 'flex',
  alignItems: 'center',

  '.icon': {
    marginRight: 8,
  },

  '.text': {
    fontWeight: 600,
    color: theme.blue,
  },
};
