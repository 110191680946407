import { theme } from 'styles';

export const affiliateContainer = {
  position: 'relative',

  '.header-image': {
    height: 600,

    '@media (max-width: 576px)': {
      height: 650,
    },
  },

  '.affiliate-header-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 90,
    width: '100%',
    zIndex: 1,
    padding: '0 2em',

    '.logo': {
      width: 196,
      height: 193,
      marginBottom: 32,

      '@media (max-width: 992px)': {
        marginBottom: 12,
      },
    },

    '.header-title': {
      fontSize: 44,
      fontWeight: 400,
      color: theme.primaryLight,
      textAlign: 'center',
      marginBottom: 12,
      maxWidth: 500,

      '@media (max-width: 992px)': {
        width: '100%',
        fontSize: 40,
      },
    },

    '.header-subtitle': {
      fontSize: 18,
      fontWeight: 300,
      color: theme.primaryLight,
      maxWidth: 630,
      textAlign: 'center',
      marginBottom: 40,

      '@media (max-width: 992px)': {
        marginBottom: 12,
      },

      '@media (max-width: 767px)': {
        textAlign: 'left',
      },

      '@media (max-width: 576px)': {
        textAlign: 'center',
      },
    },

    '.apply-button': {
      textTransform: 'uppercase',
      padding: '1em 4em',
    },

    '@media (max-width: 992px)': {
      top: 30,
    },

    '@media (max-width: 767px)': {
      top: 90,
    },
  },

  '.about-program-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: theme.secondaryDark,
    padding: '3em 9.25em 0',
    position: 'relative',

    '.about-program-info': {
      marginRight: 40,
      position: 'absolute',
      top: '25%',
      left: '9.25em',
      maxWidth: 500,

      '.about-title': {
        fontSize: 40,
        fontWeight: 400,
        color: theme.black,
        marginBottom: 12,
      },

      '.about-description': {
        fontSize: 16,
        fontWeight: 300,
        color: theme.primaryLight,
        marginBottom: 24,
      },

      '.about-button': {
        textTransform: 'uppercase',
        padding: '1em 4em',
        backgroundColor: 'transparent',
        border: `1px solid ${theme.grayLight}`,

        '@media (max-width: 767px)': {
          width: '100%',
        },
      },

      '@media (max-width: 992px)': {
        position: 'relative',
        padding: '3em',
        maxWidth: '100%',
        left: 0,
        marginRight: 0,
      },
    },

    '.affiliate-women-image': {
      width: '75%',
      maxHeight: 650,

      '@media (max-width: 767px)': {
        width: '100%',
      },
    },

    '@media (max-width: 1199px)': {
      padding: '9em 0 0 0',
      width: '100%',
    },

    '@media (max-width: 992px)': {
      justifyContent: 'flex-start',
      padding: '6em 0 0 0',
      width: '100%',
    },

    '@media (max-width: 767px)': {
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      padding: '3em 0 0 0',
      width: '100%',
    },
  },

  '.receive-perks-container': {
    position: 'relative',
    padding: '3em 9.25em 4em',
    backgroundColor: theme.black,
    color: theme.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '.sticker-image': {
      position: 'absolute',
      top: -120,
      right: 'calc(100vw - 55%)',
      width: 250,
      height: 250,
      zIndex: 100,

      '@media (max-width: 767px)': {
        width: 150,
        height: 150,
        top: -70,
        right: 'calc(100vw - 60%)',
      },
    },

    '.about-title': {
      paddingTop: '3em',
      marginBottom: 48,
      fontSize: 40,
      fontWeight: 400,

      '@media (max-width: 576px)': {
        paddingTop: '1em',
        fontSize: 36,
      },
    },

    '.receive-info-container': {
      marginBottom: 40,

      '.receive-image': {
        width: 80,
        height: 80,
        marginBottom: 24,
      },

      '.receive-title': {
        fontSize: 26,
        fontWeight: 400,
        marginBottom: 16,
      },

      '.receive-description': {
        fontSize: 16,
        fontWeight: 300,
        lineHeight: 1.5,
      },
    },

    '.receive-horizontal-line': {
      width: '100%',
      height: 0.5,
      backgroundColor: theme.white,
      marginBottom: 40,
    },

    '.ready-title': {
      fontSize: 26,
      fontWeight: 400,
      marginBottom: 32,
    },

    '.ready-button': {
      textTransform: 'uppercase',
      padding: '1em 4em',
      backgroundColor: theme.white,
      color: theme.black,
      marginBottom: 40,

      '@media (max-width: 576px)': {
        width: '100%',
      },
    },

    '@media (max-width: 767px)': {
      padding: '3em',
    },
  },
};
