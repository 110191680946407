import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import { flipInXAnimation } from '../../../styles';
import { collapseContainer, collapseHeader } from './styles';

const Collapse = (props) => {
  const { header, children, className } = props;
  const childsRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div css={collapseContainer(props)} {...(className && { className })}>
      <div
        role="button"
        tabIndex={0}
        onClick={() => setIsOpen((prev) => !prev)}
        className="collapse-header"
        css={collapseHeader(isOpen)}>
        <span className="collapse-title">{header}</span>
        <Icon material size={16} iconName="expand_more" color="textLightSecondary" />
      </div>
      {isOpen && (
        <div ref={childsRef} className="collapse-content" css={flipInXAnimation()}>
          {children}
        </div>
      )}
    </div>
  );
};

Collapse.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
};

export default Collapse;
