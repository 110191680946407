import { useStore, createStore, showError } from 'components';
import { getAllCountries } from 'services';

const countriesStore = createStore({});

export const initCountries = async () => {
  const [res, err] = await getAllCountries();
  if (err) showError(err);

  countriesStore.setState(res.data);
};

export const useCountries = (...args) => useStore(countriesStore, ...args);
