import {
  // REMOVE TAG HIDE FOR FIRST VERSION
  // BecomeAnAffiliateForm,
  Ingredients,
  ProductDetails,
} from 'screens/public';
import { ROUTES } from 'enums';

export const PublicRoutes = {
  products: {
    subMenus: [
      {
        url: '/products/1',
        state: { productId: 1 },
        label: 'cleansingGel',
        component: ProductDetails,
      },
      {
        url: '/products/2',
        state: { productId: 2 },
        label: 'fillerSerum',
        component: ProductDetails,
      },
      {
        url: '/products/3',
        state: { productId: 3 },
        label: 'eyeContouringCream',
        component: ProductDetails,
      },
      {
        url: '/products/4',
        state: { productId: 4 },
        label: 'dayCreamAndMoisturizer',
        component: ProductDetails,
      },
      {
        url: '/products/5',
        state: { productId: 5 },
        label: 'nightCream',
        component: ProductDetails,
      },
      {
        url: '/products/6',
        state: { productId: 6 },
        label: 'calmingAndHydratingMask',
        component: ProductDetails,
      },
      {
        url: '/products/7',
        state: { productId: 7 },
        label: 'metabolicActivatingMask',
        component: ProductDetails,
      },
    ],
    internal: [
      {
        url: ROUTES.Ingredients,
        label: 'Ingredients',
        component: Ingredients,
      },
    ],
  },
  // REMOVE TAG HIDE FOR FIRST VERSION
  // becomeAnAffiliate: {
  //   internal: [
  //     {
  //       url: ROUTES.BecomeAnAffiliateApply,
  //       label: 'BecomeAnAffiliateApply',
  //       component: BecomeAnAffiliateForm,
  //     },
  //   ],
  // },
};
