import { theme } from 'styles';

export const productContainer = {
  '.product-container': {
    '.product-image': {
      marginBottom: 16,
    },

    '.product-info-container': {
      padding: 16,

      '.product-title': {
        textTransform: 'uppercase',
        fontSize: 20,
        color: theme.black,
        fontWeight: 400,
      },

      '.price-icon-quantity-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,

        '.product-price': {
          fontSize: 22,
          marginRight: 8,
          color: theme.black,
          fontWeight: 400,
        },

        '.info-tooltip': {
          marginRight: 8,

          '.icon': {
            color: theme.black,
          },
        },

        '.product-quantity': {
          fontSize: 22,
          color: theme.black56,
          fontWeight: 400,
        },
      },

      '.action-container': {
        visibility: 'hidden',
        opacity: 0,
        textTransform: 'uppercase',
        display: 'flex',
        flexWrap: 'wrap',

        '.button-default': {
          flex: 1,
          color: theme.white,
          border: `1px solid ${theme.white}`,

          '&:hover': {
            color: theme.black,
          },
        },

        '.button-link': {
          flex: 1,
          color: theme.white,
          textTransform: 'uppercase',

          '&:active': { outline: 'none' },
        },
      },
    },

    '&:hover': {
      backgroundColor: theme.blackLight,
      color: theme.white,
      cursor: 'pointer',
    },
  },

  '.product-container:hover .action-container': {
    display: 'flex',
    flexDirection: 'row',
    visibility: 'visible',
    opacity: 1,
    color: theme.white,
  },

  '.product-container:hover .product-title': {
    color: theme.white,
  },

  '.product-container:hover .price-icon-quantity-container .product-price': {
    color: theme.white,
  },

  '.product-container:hover .price-icon-quantity-container .info-tooltip .icon': {
    color: theme.white,
  },

  '.product-container:hover .price-icon-quantity-container .product-quantity': {
    color: theme.white56,
  },
};

export const contentInfoTooltipContainer = {
  '& .tooltip-content': {
    width: 250,
  },
};
