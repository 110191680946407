import { theme } from 'styles';

export const affiliateContainer = {
  position: 'relative',

  '.container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8em 0',
    color: theme.white,
    backgroundColor: theme.black,

    '.affiliate-title': {
      fontWeight: 400,
      fontSize: 32,
      marginBottom: 40,

      '@media (max-width: 576px)': {
        padding: '0 24px',
      },
    },

    '.affiliate-button': {
      textTransform: 'uppercase',
      marginBottom: 36,
      fontSize: 16,
      fontWeight: 400,
      padding: '1em 5em',

      '&:active': { outline: 'none' },

      '@media (max-width: 767px)': {
        width: '90%',
      },
    },

    '.batch': {
      color: theme.gray700,
      backgroundColor: `${theme.blackLight} !important`,
      zIndex: 10,
      borderRadius: 2,
      border: `1px solid ${theme.blackLight}`,
      transform: 'rotate(-15deg)',
      textTransform: 'uppercase',
      padding: '1em 7em',
      letterSpacing: '0.3em',
    },
  },
};
