import { useEffect } from 'react';
import { isFunction } from 'lodash-es';

const DEFAULT_ROOT_MARGIN = '0px';
const DEFAULT_THRESHOLD = [0];

export const useIntersectionObserver = (targetNode, rootNode, callback, options) => {
  const targetRef = targetNode?.current ?? targetNode;
  const { rootMargin = DEFAULT_ROOT_MARGIN, threshold = DEFAULT_THRESHOLD } = options ?? {};
  const rootRef = rootNode?.current ?? rootNode;

  useEffect(() => {
    let observer = null;

    if (targetRef instanceof Element && rootRef instanceof Element && isFunction(callback)) {
      const observeOptions = { root: rootRef, rootMargin, threshold };
      observer = new IntersectionObserver(entries => {
        entries.forEach(entry => callback(entry));
      }, observeOptions);
      observer.observe(targetRef);
    }

    return () => {
      if (observer) observer.unobserve(targetRef);
    };
  }, [targetRef, rootRef]);
};
