import { useState, useEffect, useImperativeHandle } from 'react';
import { isFunction, isObject, omit } from 'lodash-es';
import validateInput from '../inputValidation';

export const useDateTimePicker = ({
  value: initValue,
  onChange,
  onError,
  validate,
  disabled,
  required,
  isTouched: isTouchedInit,
  componentRef,
  dateFormat,
  ...restProps
}) => {
  const strVal = initValue?.toString ? initValue.toString() : initValue ?? '';
  const [value, setValue] = useState(strVal);
  const [error, setError] = useState();
  const [isTouched, setIsTouched] = useState(isTouchedInit);

  useEffect(() => {
    setValue(strVal);
  }, [strVal]);

  useEffect(() => {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);

  useEffect(() => {
    isTouched && checkForError(value);
  }, [isTouched]);

  useImperativeHandle(componentRef, () => ({
    value,
    changeValue: newValue => handleChange({ target: { value: newValue } }),
    checkForError,
    setError: handleError,
    changeIsTouched: setIsTouched,
  }));

  const handleChange = newValue => {
    if (!isObject(newValue)) return;
    setValue(newValue?.format(dateFormat));
    checkForError(newValue);
    isFunction(onChange) && onChange(newValue);
  };

  const clearDates = inputProps => () => {
    if (!value) return;
    inputProps.onChange({ target: { value: '' } });
    setValue(null);
    isFunction(onChange) && onChange(null);
  };

  const handleBlur = () => {
    !isTouched && setIsTouched(true);
    checkForError(value);
  };

  const checkForError = newValue => {
    let newError = null;

    if (isFunction(validate)) newError = validate(newValue);
    else if (required) newError = validateInput('required')(newValue);

    handleError(newError);
  };

  const handleError = newError => {
    isTouched && setError(newError);
    isFunction(onError) && onError(newError);
  };

  return {
    // Input props
    value,
    onChange: handleChange,
    onBlur: handleBlur,
    clearDates,
    disabled,
    // Form related props
    error,
    isTouched,
    restProps: omit(restProps, ['className', 'label', 'horizontal', 'inputRef', 'formId']),
  };
};
