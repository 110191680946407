import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { ColorBatch, Image } from 'components';
import { ingredientsContainer } from './styles';

const IngredientsSlider = (props) => {
  const { ingredients, className } = props;
  const { getText } = useTranslations();
  const scrollContainerRef = useRef();
  const cardRef = useRef();

  return (
    <div css={ingredientsContainer} {...(className && { className })}>
      <div className="ingredients-badge-container">
        <div className="infinite-container" ref={scrollContainerRef}>
          {ingredients?.map((el, ind) => (
            <div key={ind} className="badge" ref={cardRef}>
              <ColorBatch withoutStartCase type="default" className="ingredient-badge">
                <div className="batch-container">
                  <Image src={el.icon} className="badge-image" />
                  <h5 className="badge-text">{getText(el.title)}</h5>
                </div>
              </ColorBatch>
            </div>
          ))}
          {ingredients?.map((el, ind) => (
            <div aria-hidden key={ind} className="badge" ref={cardRef}>
              <ColorBatch withoutStartCase type="default" className="ingredient-badge">
                <div className="batch-container">
                  <Image src={el.icon} className="badge-image" />
                  <h5 className="badge-text">{getText(el.title)}</h5>
                </div>
              </ColorBatch>
            </div>
          ))}
          {ingredients?.map((el, ind) => (
            <div aria-hidden key={ind} className="badge" ref={cardRef}>
              <ColorBatch withoutStartCase type="default" className="ingredient-badge">
                <div className="batch-container">
                  <Image src={el.icon} className="badge-image" />
                  <h5 className="badge-text">{getText(el.title)}</h5>
                </div>
              </ColorBatch>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

IngredientsSlider.propTypes = {
  ingredients: PropTypes.array,
  className: PropTypes.string,
};

export default IngredientsSlider;
