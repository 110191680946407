import { useRef, useEffect, useState, useLayoutEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { loader, spinnerContainer } from './styles';

const Spinner = forwardRef((props, reference) => {
  const { size: sizeProp = 50, loading = false, fullScreen, text, className } = props;
  const [size, setSize] = useState(sizeProp);
  const [isLoading, setIsLoading] = useState(loading);
  const loaderRef = useRef(null);

  useImperativeHandle(reference, () => ({
    isLoading,
    showLoading: () => setIsLoading(true),
    hideLoading: () => setIsLoading(false),
    changeSize: (newSize) => setSize(newSize),
  }));

  useLayoutEffect(() => {
    const container = loaderRef.current.parentNode;
    const { height, width } = container.getBoundingClientRect();

    if (height === 0 || width === 0) return;

    // If the component is smaller than the default size set the size to half of component size
    if (height / 2 < size || width / 2 < size) setSize(Math.min(height, width) / 2);
  }, []);

  useEffect(() => {
    loaderRef.current && toggleLoader(isLoading);
    isLoading && !fullScreen && loaderRef.current?.parentNode && setUpPosition();

    return () => {
      loaderRef.current && toggleLoader(false);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading]);

  useEffect(() => {
    sizeProp !== size && setSize(sizeProp);
    loading !== isLoading && setIsLoading(loading);
  }, [sizeProp, loading]);

  const setUpPosition = () => {
    const container = loaderRef.current.parentNode;
    const { top, bottom } = container.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // If the component to which the loader will be applied is bigger than the window height
    // we need to put the spinner into the middle of the screen so the user can see it even when scroll
    if (top < windowHeight && bottom > windowHeight) {
      loaderRef.current.style.top = `${windowHeight / 2 - top - size / 2}px`;
      // Listen for changes into the scroll and place the loader into the middle of the screen
      window.addEventListener('scroll', handleScroll);
    }
  };

  const handleScroll = () => {
    if (!loaderRef.current?.parentNode) window.removeEventListener('scroll', handleScroll);
    else {
      const { parentNode } = loaderRef.current;
      const windowHeight = window.innerHeight;
      const { top } = parentNode.getBoundingClientRect();
      loaderRef.current.style.top = `${windowHeight / 2 - top - size / 2}px`;
    }
  };

  const toggleLoader = (show) => {
    const { parentNode, style: loaderStyles } = loaderRef.current;
    const { style: parentStyles, childNodes } = parentNode;

    parentStyles.position = show ? 'relative' : '';
    parentStyles.pointerEvents = show ? 'none' : '';
    loaderStyles.display = show ? 'block' : 'none';

    // If there is only the loader inside the parent exit from function
    if (childNodes.length === 1) return;

    show ? wrapChilds() : unwrapChilds();
  };

  // Put all childrens into container with blur effect and no pointer events so it will be disabled
  const wrapChilds = () => {
    const { parentNode } = loaderRef.current;
    const { childNodes } = parentNode;

    const blurContainer = document.createElement('div');
    blurContainer.style.filter = 'blur(3px)';
    blurContainer.id = 'blurContainer';
    className && blurContainer.classList.add(className);
    parentNode.insertBefore(blurContainer, loaderRef.current);

    const validChilds = [...childNodes];
    validChilds.forEach(
      (child) =>
        !child.isSameNode(loaderRef.current) && !child.isSameNode(blurContainer) && blurContainer.appendChild(child),
    );
  };

  const unwrapChilds = () => {
    const { parentNode } = loaderRef.current;
    const { childNodes } = parentNode;
    const blurContainer = childNodes.item(
      [...childNodes].findIndex((el) => el.getAttribute && el.getAttribute('id') === 'blurContainer'),
    );

    if (!blurContainer) return;

    [...blurContainer.childNodes].forEach((el) => parentNode.appendChild(el));
    parentNode.removeChild(blurContainer);
  };

  return (
    <div css={spinnerContainer} ref={loaderRef}>
      <svg css={loader(size, props)} className="spinner" width="66" height="24" viewBox="0 0 66 24" fill="none">
        <g id="Group 9870">
          <circle className="path" id="Ellipse 654" cx="6" cy="15" r="6" fill="#212E4B" />
          <circle className="path" id="Ellipse 655" cx="24" cy="6" r="6" fill="#50B08B" />
          <circle className="path" id="Ellipse 656" cx="42" cy="12" r="6" fill="#75C1E3" />
          <circle className="path" id="Ellipse 657" cx="60" cy="18" r="6" fill="#F6C159" />
        </g>
      </svg>
      {text && <p className="spinner-text">{text}</p>}
    </div>
  );
});

Spinner.propTypes = {
  size: PropTypes.number,
  loading: PropTypes.bool,
  fullScreen: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default Spinner;
