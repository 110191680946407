import { useHistory, useParams } from 'react-router-dom';
import qs from 'query-string';
import { mapValues, isPlainObject, omit, omitBy, isNil, isArray, isNumber } from 'lodash-es';
import moment from 'moment';
import { isJsonString, parseJson, deepEqual } from '../utils';

const dateTimeFormat = 'MM-DD-YYYY';

export const useUrlParams = () => {
  const history = useHistory();
  const pathParams = useParams();
  const { location } = history;
  const { pathname, search, state } = location;

  const queryParams = mapValues(
    qs.parse(search, {
      arrayFormat: 'index',
    }),
    (el) => {
      switch (true) {
        case isJsonString(el):
          return parseJson(el);
        case (/\d-\d-\d/g.test(el) || /\d\/\d\/\d/g.test(el)) && moment(el, dateTimeFormat).isValid():
          return moment(el, dateTimeFormat);
        case isNumber(el):
          return Number(el);
        default:
          return el;
      }
    },
  );

  const setQueryParams = (params) => {
    const mappedQueryParams = omitBy(
      mapValues({ ...queryParams, ...params }, (el) => {
        switch (true) {
          case isPlainObject(el):
            return JSON.stringify(el);
          case moment.isMoment(el):
            return el.format(dateTimeFormat);
          case moment.isDate(el):
            return moment(el).format(dateTimeFormat);
          default:
            return el;
        }
      }),
      isNil,
    );

    const filteredUrlParams = qs.stringify(mappedQueryParams, { arrayFormat: 'index' });
    const newParams = filteredUrlParams ? `?${filteredUrlParams}` : search;
    !deepEqual(queryParams, mappedQueryParams) && history.replace({ to: pathname, state, search: newParams });
  };

  const clearQueryParams = (paramsToClear) => {
    let newSearch = '';
    if (isArray(paramsToClear)) newSearch = `?${qs.stringify(omit(queryParams, paramsToClear))}`;

    history.replace({ to: pathname, state, search: newSearch });
  };

  return {
    pathParams,
    queryParams,
    setQueryParams,
    clearQueryParams,
    stringifyQueryParams: (params, config) => qs.stringify(params, config),
    parseQueryParams: (params, config) => qs.parse(params, config),
  };
};
