import { useEffect } from 'react';
import moment from 'moment';
import { camelCase, startCase } from 'lodash-es';
import { getText } from '@veraio/strank';
import { Button, Col, ColorBatch, Icon, Image, Row } from 'components';
import { AFFILIATE_STATUS, ROUTES } from 'enums';
import { useUserStore } from 'stores';
import { affiliateStatusMap, affiliateProducts } from './config';
import { myAffiliateContainer, myApprovedAffiliateContainer } from './styles';
import { copyToClipboard } from 'utils';

const MyAffiliates = ({ history }) => {
  const user = useUserStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return user.affiliates?.status !== AFFILIATE_STATUS.APPROVED ? (
    <div css={myAffiliateContainer}>
      <h3 className="title">{startCase(getText('yourAffiliateApplication'))}</h3>
      <Row gap={24}>
        <Col xl={5} lg={5} md={5} sm={12} xs={12}>
          <p className="text">{`${getText('sent')}: ${moment(user.affiliates?.createdDate).format(
            'DD.MM.YYYY HH:MM',
          )}`}</p>
          <p className="text">{`${getText('status')}:`}</p>
          <ColorBatch type={affiliateStatusMap[user.affiliates?.status]?.color} className="affiliate-badge">
            {getText(`${camelCase(user.affiliates?.status)}`)}
          </ColorBatch>
        </Col>
        <Col xl={7} lg={7} md={7} sm={12} xs={12}>
          <h4 className="affiliate-title">{getText(affiliateStatusMap[user.affiliates?.status]?.title)}</h4>
          <p className="text">
            {getText(affiliateStatusMap[user.affiliates?.status]?.description)}{' '}
            <a href="mailto: onevita@oneecosystem.eu" className="affiliate-link">
              onevita@oneecosystem.eu
            </a>
          </p>
          <Button type="secondary" className="button" linkTo={ROUTES.Products}>
            {getText('exploreOneVita')}
          </Button>
        </Col>
      </Row>
    </div>
  ) : (
    <div css={myApprovedAffiliateContainer}>
      <div className="approved-header-info-container">
        <div className="approved-header-info">
          <h3 className="approved-title">{getText('congratulations')}</h3>
          <p className="approved-description">{getText('youCanNowBeAffiliateOfOneVita')}</p>
        </div>
        <Image
          src="https://oneecosystem.blob.core.windows.net/vita/apps/images/affiliate-sticker.svg"
          className="sticker-image"
        />
      </div>
      <h4 className="approved-table-title">{getText('yourAffiliateLinks')}</h4>
      <div className="approved-table-container">
        {affiliateProducts.map((el, index) => (
          <div key={index} className="approved-table-row">
            <div
              className="approved-table-row-product"
              role="button"
              tabIndex={0}
              onClick={() => history.push({ pathname: `/products/${el.id}`, state: el })}>
              <Image src={el.image} className="approved-table-row-product-image" />
              <p className="approved-table-row-product-title">{getText(el.title)}</p>
              <Icon iconName="north_east" className="icon-arrow" size={16} color="gray200" />
            </div>
            <div className="approved-table-row-link">
              <p className="approved-table-row-link-text">{el.link}</p>
              <Button
                type="default"
                small
                leftIcon={<Icon iconName="link" className="icon-link" size={16} />}
                className="link-button"
                onClick={() => copyToClipboard(el.link, 'successfullyCopiedProduct')}>
                {getText('copy')}
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="approved-footer-info-container">
        <div className="approved-footer-info">
          <p className="approved-footer-description">{getText('pleaseDoNotHesitateToContactUsWithAnyQuestions')}</p>
          <a href="mailto: onevita@oneecosystem.eu" className="approved-email">
            onevita@oneecosystem.eu
          </a>
        </div>
        <div className="approved-date-status">
          <ColorBatch type={affiliateStatusMap[user.affiliates?.status]?.color} className="affiliate-badge">
            {getText(`${camelCase(user.affiliates?.status)}`)}
          </ColorBatch>
          <p className="modified-date">{`${getText('since')}: ${moment(user.affiliates?.createdDate).format(
            'DD.MM.YYYY HH:MM',
          )}`}</p>
        </div>
      </div>
    </div>
  );
};

export default MyAffiliates;
