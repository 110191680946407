import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Row, Col } from 'components';
import { counterInputContainer, icon } from './styles';

const CounterInput = forwardRef((props, ref) => {
  const { value, onChange, isOutOfRange, className } = props;
  const [newValue, setNewValue] = useState(value ?? 0);
  const isDisabled = newValue === 0 ?? value === 0;

  useEffect(() => {
    onChange(newValue);
  }, [newValue]);

  useImperativeHandle(ref, () => ({
    value: newValue,
  }));

  const handleRemoveNumber = (e) => {
    if (isDisabled) return e.preventDefault();

    setNewValue((prev) => prev - 1);
  };

  const handleAddNumber = () => {
    !isOutOfRange && setNewValue((prev) => prev + 1);
  };

  return (
    <Row css={counterInputContainer(props)} {...(className && { className })}>
      <Col sm={3} md={3} lg={4}>
        <Icon material iconName="do_not_disturb_on" onClick={handleRemoveNumber} size={44} css={icon(isDisabled)} />
      </Col>
      <Col sm={6} md={6} lg={4} displayFlex align="center" justify="center">
        <p className="number">{newValue}</p>
      </Col>
      <Col sm={3} md={3} lg={4}>
        <Icon material iconName="add_circle" onClick={handleAddNumber} size={44} css={icon()} />
      </Col>
    </Row>
  );
});

CounterInput.propTypes = {
  value: PropTypes.number,
  isOutOfRange: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default CounterInput;
