import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isString, isFunction, isNil, uniqBy, omit } from 'lodash-es';
import { useCountries } from 'stores';
import { ReactComponent as DefaultCountryImage } from 'assets/images/default-country-image.svg';
import InputContainer from '../InputContainer';
import { usePhoneInput } from './usePhoneInput';
import Input from '../Input';
import Dropdown from '../Dropdown';
import {
  phoneInputContainer,
  phoneInputCountrySelect,
  countryContainer,
  countryImage,
  countryListLabel,
} from './styles';

const numberPattern = (val) => /[0-9]/.test(val);

const PhoneInput = (props) => {
  const {
    required,
    codeLabel,
    placeholder,
    codePlaceholder,
    validate,
    disabled,
    small,
    inputProps,
    dropdownProps,
    className,
  } = props;
  const countries = useCountries();

  const countryListOptions = uniqBy(
    countries?.length &&
      countries?.map((el) => ({
        ...omit(el, ['phoneCode']),
        phone: el.phoneCode,
        label: (
          <div css={countryContainer}>
            {!isNil(el.flag) ? (
              <div>{el.flag}</div>
            ) : (
              <div css={countryImage}>
                <DefaultCountryImage />
              </div>
            )}
            <span css={countryListLabel}>{`+${el.phoneCode}`}</span>
          </div>
        ),
        duplicates: countries.filter((i) => i.phoneCode === el.phoneCode).map((i) => i.name),
      })),
  );

  const { inputValue, dropdownValue, handleBlur, handleInputChange, handleDropdownChange, error, isTouched } =
    usePhoneInput(props, countryListOptions);

  const hasError = isTouched && error;
  const hasValidation = isFunction(validate) || required;

  return (
    <InputContainer {...props} error={error} isTouched={isTouched}>
      <div css={phoneInputContainer(hasValidation)} {...(className && { className })}>
        <Dropdown
          noClear
          withSearch
          small={small}
          disabled={disabled}
          options={countryListOptions}
          value={dropdownValue}
          placeholder={isString(codePlaceholder) ? codePlaceholder : ''}
          label={codeLabel}
          onChange={handleDropdownChange}
          css={phoneInputCountrySelect}
          onBlur={handleBlur}
          renderSelected={(val) => val?.label}
          mapValue={(val) => val?.phone}
          hasError={hasError && !dropdownValue}
          displayKey="label"
          uniqueKey="phone"
          searchKeys={['phone', 'duplicates']}
          className="phone-dropdown"
          {...dropdownProps}
        />
        <Input
          small={small}
          disabled={disabled}
          value={inputValue}
          placeholder={isString(placeholder) ? placeholder : ''}
          onChange={handleInputChange}
          onBlur={handleBlur}
          pattern={numberPattern}
          hasError={hasError && !inputValue?.length}
          className="phone-input"
          {...inputProps}
        />
      </div>
    </InputContainer>
  );
};

PhoneInput.propTypes = {
  label: PropTypes.string,
  codeLabel: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  codePlaceholder: PropTypes.string,
  validate: PropTypes.func,
  horizontal: PropTypes.bool,
  dropdownProps: PropTypes.any,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  isTouched: PropTypes.bool,
  inputProps: PropTypes.object,
  className: PropTypes.string,
};

const PhoneInputRef = forwardRef((props, ref) => <PhoneInput {...props} componentRef={ref} />);

PhoneInputRef.displayName = 'PhoneInput';

export default PhoneInputRef;
