import { useState, useEffect, useDebugValue } from 'react';
import { isFunction } from 'lodash-es';
import { deepEqual } from '../utils';

export const useStore = (store, selectorCallback) => {
  const selector = isFunction(selectorCallback) ? selectorCallback : state => state;
  const value = selector(store.getState());

  const [{ inst }, forceUpdate] = useState({ inst: { value: store.getState(), getSnapshot: store.getState } });

  useEffect(() => {
    if (!deepEqual(selector(inst.value), selector(inst.getSnapshot())))
      forceUpdate({ inst: { value: store.getState(), getSnapshot: store.getState } });

    const handleStoreChange = (nextState, prevState) => {
      if (!deepEqual(selector(nextState), selector(prevState)))
        forceUpdate({ inst: { value: store.getState(), getSnapshot: store.getState } });
    };

    return store.subscribe(handleStoreChange);
  }, [store.subscribe]);

  useDebugValue(value);
  return value;
};
