/* eslint-disable no-console */
import axios from 'axios';
import qs from 'query-string';

axios.defaults.paramsSerializer = {
  encode: qs.parse,
  serialize: params => qs.stringify(params, { indices: false }),
};

const performRequest =  async (method, url, body, config) => {
  try {
    const params = body ? [url, body, config] : [url, config];
    const response = await method(...params);
    return Promise.resolve([response.data, null]);
  } catch (err) {
    console.log(err);
    return Promise.resolve([null, err]);
  }
};

export const getReq = (url, config) => performRequest(axios.get, url, null, config);

export const postReq = (url, body, config) => performRequest(axios.post, url, body, config);

export const putReq = (url, body, config) => performRequest(axios.put, url, body, config);

export const patchReq = (url, body, config) => performRequest(axios.patch, url, body, config);

export const deleteReq = (url, config) => performRequest(axios.delete, url, null, config);

