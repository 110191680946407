import { cloneElement, useState, useEffect, useRef } from 'react';
import {
  Link,
  // REMOVE TAG HIDE FOR FIRST VERSION
  //  useHistory
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { isFunction, debounce } from 'lodash-es';
import {
  Icon,
  //  User
} from 'components';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { ROUTES } from 'enums';
// import { useUserStore } from 'stores';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { useNavigationControls } from '../NavigationContext';
import { sideNavContainer, menuListContainer, sideNavHeader } from './styles';

const MenuList = ({ sideNavChilds, footer, homeRoute, closeBreakPoint }) => {
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const isAuthenticated = useUserStore((userState) => userState.isAuthenticated);
  // const history = useHistory();
  const { setupSideNavControls } = useNavigationControls();
  const [isOpen, setIsOpen] = useState(window.innerWidth <= 767);
  const [isOpenNavigation, setIsOpenNavigation] = useState(false);
  const [breakPoint, setBreakPoint] = useState(closeBreakPoint);
  const previousWidthRef = useRef(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    setupSideNavControls({
      isOpen,
      isOpenNavigation,
      close: () => setIsOpen(false),
      open: () => setIsOpen(true),
      toggle: () => setIsOpen((prev) => !prev),
      changeBreakPoint: (val) => setBreakPoint(val),
      openNavigation: () => setIsOpenNavigation(true),
      closeNavigation: () => setIsOpenNavigation(false),
    });

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [isOpen, isOpenNavigation]);

  useEffect(() => {
    setBreakPoint(closeBreakPoint);
    setIsOpen(window.innerWidth >= closeBreakPoint);
  }, [closeBreakPoint]);

  const handleWindowResize = debounce((e) => {
    if (e.currentTarget?.innerWidth < breakPoint && previousWidthRef.current >= breakPoint) setIsOpen(false);
    else if (e.currentTarget?.innerWidth >= breakPoint && previousWidthRef.current < breakPoint) setIsOpen(true);
    previousWidthRef.current = e.currentTarget?.innerWidth;
  }, 500);

  const MenuListItems = sideNavChilds?.map((menu, i) =>
    cloneElement(menu, {
      key: `${menu.label}-${i}`,
      isOpen: isOpenNavigation,
    }),
  );

  const Footer = isFunction(footer) && footer;

  return (
    <aside css={sideNavContainer} role="navigation">
      <header css={sideNavHeader}>
        <Link to={homeRoute} className="logo-container">
          <Logo className="logo" />
        </Link>
        <div className="action-container">
          <Icon
            iconName={!isOpenNavigation ? 'density_medium' : 'close'}
            className="icon"
            onClick={() => setIsOpenNavigation((prev) => !prev)}
          />
          {/* REMOVE TAG HIDE FOR FIRST VERSION */}
          {/* <Icon iconName="shopping_cart" className="icon" />
          <Icon iconName="apps" className="icon" />
          {isAuthenticated ? (
            <User />
          ) : (
            <Icon iconName="login" className="icon" onClick={() => history.push(ROUTES.Login)} />
          )} */}
        </div>
      </header>
      <section className="menu-list-container" css={menuListContainer(isOpenNavigation)}>
        {MenuListItems}
      </section>
      {Footer && <Footer isOpen={isOpen} setIsOpen={setIsOpen} />}
    </aside>
  );
};

MenuList.propTypes = {
  sideNavChilds: PropTypes.array,
  footer: PropTypes.any,
  homeRoute: PropTypes.string,
  closeBreakPoint: PropTypes.number,
};

export default MenuList;
