import { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button, Icon } from 'components';
import { container, stepItem } from './styles';

const Stepper = forwardRef((props, ref) => {
  const { steps, withButton, withNumber, hasStepClick, className } = props;
  const { getText } = useTranslations();
  const [currentStep, setCurrentStep] = useState(1);
  const [isCompletedStepper, setIsCompletedStepper] = useState(false);
  const completedSteps = steps.filter((el) => el.id < currentStep);

  useImperativeHandle(ref, () => ({
    currentStep,
    changeActive: (newValue) => setCurrentStep(newValue),
    isCompletedStepper,
    completedSteps,
    handleCompletedStep: (newValue) => handleCompletedStep(newValue),
    handleCompletedStepper: (newValue) => setIsCompletedStepper(newValue),
  }));

  const handleCompletedStep = (val) => setCurrentStep(val);

  return (
    <div ref={ref} css={container(props)} {...(className && { className })}>
      <div className="step-container">
        {steps.map((el, index) => (
          <div
            key={index}
            role="button"
            tabIndex={0}
            className="steps"
            css={stepItem(
              currentStep === index + 1 || currentStep === el?.id,
              index + 1 < currentStep || el?.id < currentStep || isCompletedStepper,
            )}
            onClick={() => (!isEmpty(completedSteps) || hasStepClick) && handleCompletedStep(el?.id ?? index + 1)}>
            <div className="step">
              {el?.id && withNumber && (
                <div>
                  {index + 1 < currentStep || el?.id < currentStep || isCompletedStepper ? (
                    <Icon material iconName="done" color="white" />
                  ) : (
                    el?.id ?? index + 1
                  )}
                </div>
              )}
            </div>
            <p className="text">{el?.label ?? el}</p>
          </div>
        ))}
        {withButton && !isCompletedStepper && (
          <Button
            onClick={() =>
              currentStep === steps?.length ? setIsCompletedStepper(true) : setCurrentStep((prev) => prev + 1)
            }>
            {currentStep === steps?.length ? getText('finish') : getText('next')}
          </Button>
        )}
      </div>
      <div className="component-container">{steps?.find((el) => el.id === currentStep)?.component}</div>
    </div>
  );
});

Stepper.propTypes = {
  steps: PropTypes.array,
  withButton: PropTypes.bool,
  withNumber: PropTypes.bool,
  hasStepClick: PropTypes.bool,
  onStepClick: PropTypes.func,
  className: PropTypes.string,
};

export default Stepper;
