import { keyframes } from '@emotion/react';
import { theme } from 'styles';

const animate = keyframes`
0% {
  transform: translate3d(0, 0,0);
}
100% {
  transform: translate3d(-1199px, 0, 0);
}
`;

export const ingredientsContainer = {
  paddingBottom: 24,
  position: 'relative',
  width: '100vw',
  marginLeft: '-50vw',
  left: '50%',

  '.ingredients-badge-container': {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '0 16px',

    '&:before': {
      content: "''",
      width: 140,
      height: '100%',
      position: 'absolute',
      background: 'linear-gradient(to right, #F3F4F6 0%, rgba(255, 255, 255, 0) 100%)',
      zIndex: 1,
      left: 0,
      top: 0,
    },

    '&:after': {
      content: "''",
      width: 140,
      height: '100%',
      position: 'absolute',
      background: 'linear-gradient(to right, #F3F4F6 0%, rgba(255, 255, 255, 0) 100%)',
      zIndex: 1,
      right: 0,
      top: 0,
      transform: 'rotateZ(180deg)',
    },

    '.infinite-container': {
      overflow: 'hidden',
      display: 'flex',

      '.badge': {
        whiteSpace: 'nowrap',
        width: 'fit-content',
        minWidth: 140,
        marginRight: 24,
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        animation: `${animate} 20s linear 0s infinite normal none running`,

        '.ingredient-badge': {
          display: 'flex',
          backgroundColor: 'transparent',
          color: theme.black,
          marginRight: 16,
          width: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          border: 'none',

          '&:last-child': {
            marginRight: 0,
          },

          '.batch-container': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            '.badge-image': {
              width: 30,
              height: 30,
              marginRight: 12,
            },

            '.badge-text': {
              fontWeight: 400,
            },
          },
        },
      },
    },

    '@media (max-width: 767px)': {
      overflow: 'unset',
      whiteSpace: 'normal',
      width: 'auto',
    },
  },
};
