import moment from 'moment';
import avatarLogo from 'assets/images/avatar.svg';

export const myProfileModel = (data) => ({
  ...data,
  pictureUrl: data.pictureUrl ?? avatarLogo,
  picThumbnailUrl: data.picThumbnailUrl ?? avatarLogo,
  dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
  idExpiration: moment(data.idExpiration).format('YYYY-MM-DD'),
});
