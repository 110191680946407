import { useState, useEffect } from 'react';
import Icon from './Icon';

const LoadIcons = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    !isLoaded && (
      <div style={{ visibility: 'hidden' }}>
        <Icon iconName="close" material />
        <Icon iconName="close" material outlined />
        <Icon iconName="close" material twoTone />
        <Icon iconName="close" material round />
        <Icon iconName="close" material sharp />
        <Icon iconName="fab fa-btc" />
        <Icon iconName="far fa-check-circle" />
        <Icon iconName="fas fa-check-circle" />
        <Icon iconName="la la-close" />
        <Icon iconName="flaticon-close" />
        <Icon iconName="flaticon2-cross" />
        <Icon iconName="openmind i-Add-Window" />
      </div>
    )
  );
};

export default LoadIcons;
