import { Image } from 'components';
import { mainContainer } from './styles';
import image from 'assets/icons/icn-alert-error.svg';

const NotFound = () => (
  <div css={mainContainer}>
    <Image src={image} alt="404 page" width="600px" />
  </div>
);

export default NotFound;
