import { isString, isArray, isObject, isEmpty, orderBy } from 'lodash-es';
import { uuid } from '../../utils';

export const getBase64 = files =>
  new Promise(resolve => {
    let err = null;
    const result = [];
    [...files].forEach((file, ind, arr) => {
      const isLast = ind + 1 === arr.length;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        result.push({ __id: uuid(), name: file?.name, data: reader.result });
        isLast && resolve([result, null]);
      };
      reader.onerror = error => {
        err = error;
        isLast && resolve([null, err]);
      };
    });
  });

export const checkSize = (files, maxSize) => [...files].every(file => file?.size >= maxSize * 1024 * 1024);

export const prepareValue = initValue => {
  switch (true) {
    case isString(initValue) && !!initValue?.length: {
      return { __id: uuid(), data: initValue, name: initValue?.split('/').at(-1) };
    }
    case isArray(initValue) && !!initValue?.length: {
      return orderBy(
        initValue?.map(el => ({ __id: uuid(), ...el })),
        'order',
        'asc',
      );
    }
    case isObject(initValue) && !isEmpty(initValue.length): {
      return { __id: uuid(), ...initValue };
    }
    case isObject(initValue) && !isEmpty(initValue): {
      return { __id: uuid(), ...initValue };
    }
    default:
      return null;
  }
};
