import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { fromPairs } from 'lodash-es';
import { colComp } from './styles';

export const COLUMN_DISPLAY_NAME = 'GridColumn';

const Col = forwardRef((props, ref) => {
  const { children, role, tabIndex, className, onClick, onMouseDown, onMouseUp, onMouseEnter, onMouseLeave, onDrag } =
    props;

  const events = [
    ['onClick', onClick],
    ['onMouseDown', onMouseDown],
    ['onMouseUp', onMouseUp],
    ['onMouseEnter', onMouseEnter],
    ['onMouseLeave', onMouseLeave],
    ['onDrag', onDrag],
  ].filter((el) => Boolean(el[1]));

  if (events.length) events.push(['role', role || 'button'], ['tabIndex', tabIndex || -1]);

  return (
    <div {...(className && { className })} css={colComp(props)} {...fromPairs(events)} ref={ref}>
      {children}
    </div>
  );
});

Col.propTypes = {
  children: PropTypes.any,
  role: PropTypes.string,
  tabIndex: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onDrag: PropTypes.func,
};

Col.displayName = COLUMN_DISPLAY_NAME;

export default Col;
