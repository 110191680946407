export const ALL_PRODUCTS_MOCK_DATA = [
  {
    id: 1,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-cleansing-gel.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-cleansing-gel.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cleaning-gel.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-cleansing-gel.jpg',
    keyIngredientsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-cleansing-gel.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-cleancing-gel.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-cleansing-gel.jpg',
    isRightInfo: true,
    isRightImage: false,
    isMask: false,
    title: 'liposomalCleansingGel',
    footerTitle: 'cleansingGel',
    navTitle: 'cleansingGel',
    description: 'liposomalCleansingGelDescription',
    keyIngredients: 'thisCleanserContainsOnlyNaturalActiveCleansingIngredients',
    productDetailsDescription: 'ourRevolutionaryLiposomalCleansingGel',
    productIngredients: [
      'complexCleansing',
      'lipidReplacement',
      'gentleAndMoisturizing',
      'barrierBalancing',
      'mineralEnriched',
    ],
    totalPrice: 89,
    priceFiat: 44.5,
    priceCrypto: 1.0471,
    quantity: 100,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Cleansing-gel.pdf',
    dealshakerProductUrl: productUrls.cleaningGel,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitamins-icon.png',
        title: 'vitamins',
        description: 'vitaminsDescription',
        ingredientsList: [
          { id: 1, title: 'biotin', description: 'vitaminB7HelpsMaintainHealthySkin' },
          { id: 2, title: 'panthenol', description: 'formOfVitaminB5MoisturizesSkin' },
          { id: 3, title: 'thiamineHci', description: 'vitaminB1HelpsImproveSkinTexture' },
          { id: 4, title: 'cyanocobalamin', description: 'vitaminB12HelpsCellRegenerationAndImproveSkinTone' },
          { id: 5, title: 'riboflavin', description: 'vitaminB2PromotesHealthySkinByHelping' },
          { id: 6, title: 'pyridoxine', description: 'vitaminB6HelpsRegulateSebumProduction' },
          { id: 7, title: 'folicAcid', description: 'vitaminB9PromotesCellRenewalHelpingSkinLookFresh' },
          { id: 8, title: 'menadione', description: 'syntheticFormOfVitaminKSupportsSkinHealing' },
          { id: 9, title: 'bioflavonoids', description: 'theseAreAntioxidantsThatProtectSkin' },
          { id: 10, title: 'ascorbicAcid', description: 'vitaminCBrightensSkin' },
          { id: 11, title: 'tocopherylAcetate', description: 'stableFormOfVitaminEProtectsSkin' },
          { id: 12, title: 'glutathione', description: 'powerfulAntioxidantHelpsBrightenSkin' },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/minerals-rca-icon.png',
        title: 'mineralsPca',
        description: 'mineralsPcaDescription',
        ingredientsList: [
          { id: 1, title: 'sodiumPca', description: 'naturalMoisturizingIngredientHelpsKeepSkinHydrated' },
          { id: 2, title: 'magnesiumPca', description: 'helpsToHydrateAndStrengthenSkin' },
          { id: 3, title: 'zincPca', description: 'controlsExcessOilReducesAcne' },
          { id: 4, title: 'manganesePca', description: 'providesAntioxidantProtectionAndHelpsCollagenProduction' },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/fruit-enzymes-icon.png',
        title: 'fruitEnzymes',
        description: 'fruitEnzymesDescription',
        ingredientsList: [
          {
            id: 1,
            title: 'averrhoaCarambolaStarfruitFruitExtract',
            description: 'richVitaminCAntioxidantsExtractHelps',
          },
          {
            id: 2,
            title: 'passifloraIncarnataPassionfruitFruitExtract',
            description: 'packedWithVitaminsAAndCExtractNourishesSkin',
          },
          {
            id: 3,
            title: 'actinidiaChinensisKiwiFruitExtract',
            description: 'highVitaminsCAndEKiwiExtractProvidesAntioxidantBenefits',
          },
          {
            id: 4,
            title: 'garciniaMangostanaMangosteenPeelExtract',
            description: 'knownForPowerfulAntioxidantPropertiesMangosteenExtract',
          },
          {
            id: 5,
            title: 'ananasSativusPineappleFruitExtract',
            description: 'containsBromelainEnzymeGentlyExfoliatesSkin',
          },
          { id: 6, title: 'punicaGranatumPomegranateExtract', description: 'richVitaminCAntioxidantsExtractHelps' },
          {
            id: 7,
            title: 'litchiChinensisLycheeFruitExtract',
            description: 'packedWithVitaminCAndAntioxidantsLycheeExtractHelps',
          },
          {
            id: 8,
            title: 'zizyphusJujubaJujubeFruitExtract',
            description: 'knownSoothingPropertiesJujubeExtractHelps',
          },
          {
            id: 9,
            title: 'psidiumGuajavaGuavaFruitExtract',
            description: 'richVitaminsAndAntioxidantsGuavaExtractHelps',
          },
          {
            id: 10,
            title: 'leuconostocRadishRootFermentFiltrate',
            description: 'naturalFermentDerivedFromRadishRootPreservative',
          },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/b5-aloe-vera-icon.png',
        title: 'panthenolAndAloeVera',
        description: 'panthenolAndAloeVeraDescription',
        ingredientsList: [
          { id: 1, title: 'panthenol', description: 'formOfVitaminB5DeeplyHydrates' },
          { id: 2, title: 'aloeVera', description: 'soothingPlantExtractHydratesSkin' },
        ],
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/olive-leaf-extract-licorice-extract-icon.png',
        title: 'oliveLeafExtractAndLicoriceExtract',
        description: 'oliveLeafExtractAndLicoriceExtractDescription',
        ingredientsList: [
          { id: 1, title: 'oliveLeafExtract', description: 'richAntioxidantsHelpsProtectSkinFromDamage' },
          { id: 2, title: 'licoriceExtract', description: 'helpsBrightenSkinReduceDarkSpots' },
        ],
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/glycolic-acid-icon.png',
        title: 'glycolicAcid',
        description: 'glycolicAcidDescription',
      },
    ],
  },
  {
    id: 2,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-filler-serum.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-filler-serum.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/filler-serum.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-filler-serum.jpg',
    keyIngredientsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-filler-serum.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-filler-serum.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-filler-serum.jpg',
    isRightInfo: false,
    isRightImage: true,
    isMask: false,
    title: 'liposomalFillerSerum',
    footerTitle: 'fillerSerum',
    navTitle: 'fillerSerum',
    description: 'liposomalFillerSerumDescription',
    keyIngredients: 'packedWithDifferentTypesOfCollagen',
    productDetailsDescription: 'ourLiposomalPlumpingSerumInnovativeSkincareProduct',
    productIngredients: ['plumpingEffect', 'immediateTightening', 'maskingWrinkles', 'lifting', 'hydrating'],
    totalPrice: 119,
    priceFiat: 59.5,
    priceCrypto: 1.4,
    quantity: 40,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Filler-serum.pdf',
    dealshakerProductUrl: productUrls.fillerSerum,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/peptides.png',
        title: 'peptidesAcetylHexapeptidePalmitoylTripeptide',
        description: 'neuroSignallersSignalToSpecificCellsAndOrganisms',
        subDescription: 'peptidicFormPeptidesEasierAbsorbAndGetSkinMatrix',
        ingredientsList: [
          {
            id: 1,
            title: 'acetylHexapeptide8',
            description: 'alsoKnownAsBotoxInBottlePeptideHelpsReduceAppearanceOfFineLines',
          },
          { id: 2, title: 'palmitoylTripeptide1', description: 'peptideThatStimulatesCollagenProduction' },
          { id: 3, title: 'palmitoylTetrapeptide7', description: 'reducesInflammationAndPromotesSkinHealing' },
          { id: 4, title: 'palmitoylPentapeptide4', description: 'boostsCollagenAndElastinProduction' },
          { id: 5, title: 'tripeptide29', description: 'stimulatesCollagenSynthesis' },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/natural-gums.png',
        title: 'naturalGumsAcaciaGumRhizobianGumPvp',
        description: 'fillsExistingWrinkleCavityStretchesSkin',
        ingredientsList: [
          { id: 1, title: 'acaciaGum', description: 'naturalThickenerAndEmulsifierHelpsStabilizeFormulations' },
          { id: 2, title: 'rhizobianGum', description: 'naturalPolysaccharideProvidesExcellentMoisturizingProperties' },
          { id: 3, title: 'pvpPolyvinylpyrrolidone', description: 'syntheticPolymerOftenUsedFilmFormingAgent' },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sea-weeds.png',
        title: 'seaWeedsKelpSpirulina',
        description: 'naturalTightenersWithAbundanceOfMineralsAndVitamins',
        ingredientsList: [
          { id: 1, title: 'kelp', description: 'richVitaminsAndMineralsKelpDeeplyHydratesSkin' },
          { id: 2, title: 'spirulina', description: 'blueGreenAlgaePackedWithAntioxidantsAndNutrients' },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
        title: 'liposomeCaffeine',
        description: 'optimizingCapillaryCirculationMakesSkinLookBrighter',
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/collagens.png',
        title: 'collagens11Types',
        description: 'essentialProteinForBeautifulAndTightSkin',
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/elderberry-fruit.png',
        title: 'elderberryFruit',
        description: 'powerfulAntioxidantWithDirectImpactToReducingMicroInflammation',
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/allantoin.png',
        title: 'allantoin',
        description: 'naturallyOccurringManyPlantsAbilityToStimulateNewSkinGrowth',
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
        title: 'niacinamide',
        description: 'improveSkinHydrationByPreventingEvaporationOfMoistureFromSkin',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/procollagen.png',
        title: 'procollagen',
        description: 'collagenPrecursorProvideBetterCollagenSynthesis',
      },
      {
        id: 10,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/triple-size-hyaluronoc-acid.png',
        title: 'tripleSizeHyaluronicAcid',
        description: 'hydratingThreeSkinLayers',
      },
    ],
  },
  {
    id: 3,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-eye-cream.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-eye-cream.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/eye-cream.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-eye-cream.jpg',
    keyIngredientsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-eye-cream.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-eye-cream.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-eye-cream.jpg',
    isRightInfo: true,
    isRightImage: false,
    isMask: false,
    title: 'liposomalEyeCream',
    footerTitle: 'eyeCream',
    navTitle: 'eyeContouringCream',
    description: 'liposomalEyeCreamDescription',
    keyIngredients: 'packedWithLiposomeLoadedQtenAndCaffeine',
    productDetailsDescription: 'yourEyesDeserveOnlyBestOurExtraordinaryLiposomal',
    productIngredients: ['brightening', 'immediateTightening', 'plumpingAndLifting', 'antiDarkCircle', 'hydrating'],
    totalPrice: 89,
    priceFiat: 44.5,
    priceCrypto: 1.0471,
    quantity: 20,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Eye-Contouring.pdf',
    dealshakerProductUrl: productUrls.eyeCream,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitaminC-icon.png',
        title: 'vitaminCCocktailAscorbicAcidRosehipCo2ExtractSodiumAscorbylSulphate',
        description: 'topicalVitaminCScienceBackedDermatologistsAllTimeFavorite',
        ingredientsList: [
          { id: 1, title: 'ascorbicAcid', description: 'purestFormOfVitaminCBrightensSkinBoostsCollagenProduction' },
          {
            id: 2,
            title: 'rosehipCo2Extract',
            description: 'richAntioxidantsAndEssentialFattyAcidsExtractHelpsImproveSkinTexture',
          },
          {
            id: 3,
            title: 'sodiumAscorbylSulfate',
            description: 'stableFormOfVitaminCHelpsBrightenSkinReduceDarkSpots',
          },
          {
            id: 4,
            title: 'microencapsulatedVitaminC',
            description: 'encapsulatedVitaminCReleasesGraduallyProvidingLongLastingBrightness',
          },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/peptides.png',
        title: 'peptidesAcetylHexapeptide8AargirelinePalmitoylTripeptide1',
        description: 'chosenPreciselyForSignalingAntiWrinkleTighteningAndBrighteningEffects',
        subDescription: 'transformSkinContoursLongTermAndHelpSkin',
        ingredientsList: [
          {
            id: 1,
            title: 'acetylHexapeptide8Argireline',
            description: 'oftenReferredToBotoxInBottlePeptideHelpsRelaxFacialMuscles',
          },
          {
            id: 2,
            title: 'palmitoylTripeptide1',
            description: 'stimulatesCollagenProductionAndHelpsImproveSkinFirmness',
          },
          { id: 3, title: 'palmitoylTetrapeptide7', description: 'soothesInflammationAndPromotesSkinHealing' },
          { id: 4, title: 'palmitoylPentapeptide4', description: 'boostsCollagenAndElastinSynthesis' },
          { id: 5, title: 'dipeptide2', description: 'reducesPuffinessAndDarkCirclesUnderEyes' },
          {
            id: 6,
            title: 'palmitoylTetrapeptide3',
            description: 'enhancesSkinElasticityAndReducesAppearanceOfFineLines',
          },
          { id: 7, title: 'carnosine', description: 'antioxidantPeptideProtectsAgainstOxidativeStress' },
          { id: 8, title: 'tripeptide29', description: 'supportsCollagenSynthesisLeadingToSmoother' },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/antioxidants.png',
        title: 'antioxidantsCurcumaCo2LicoriceGreenTea',
        description: 'powerfulBotanicalAntiOxToHelpBrightenDarkCircles',
        ingredientsList: [
          { id: 1, title: 'curcumaCo2TurmericExtract', description: 'richInCurcuminHasPotentAntiInflammatory' },
          { id: 2, title: 'licorice', description: 'knownForSoothingPropertiesHelpsBrightenSkin' },
          { id: 3, title: 'greenTea', description: 'packedWithAntioxidantsProtectsSkinFromEnvironmentalDamage' },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/botanical-oils.png',
        title: 'botanicalOilsAbbysinianRedGrapeSeedSqualaneApricot',
        description: 'plumpersAndNutrientsWithBotanicalBackground',
        ingredientsList: [
          {
            id: 1,
            title: 'abyssinianOil',
            description: 'derivedFromAbyssinianPlantLightweightOilRichOmega9FattyAcids',
          },
          { id: 2, title: 'redGrapeSeedOil', description: 'highAntioxidantsAndLinoleicAcidHelpsProtectSkin' },
          { id: 3, title: 'squalane', description: 'highlyEffectiveMoisturizerMimicsSkinNaturalOils' },
          { id: 4, title: 'apricotOil', description: 'richVitaminsAAndENourishesAndHydratesSkin' },
        ],
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/triple-size-hyaluronoc-acid.png',
        title: 'tripleSizeHyaluronicAcid',
        description: 'glueHoldsCollagenSkinTogether',
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/beta-carotene.png',
        title: 'carrotCo2ExtractBetaCarotene',
        description: 'richestSourceOfNaturalVitaminA',
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
        title: 'liposomeCaffeine',
        description: 'byOptimizingCapillaryCirculationSkinMakesSkinLookBrighter',
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
        title: 'liposomeQTen',
        description: 'energizesSkinAndAffectsBuildUpOfCollagenToReduceWrinkles',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/calcium.png',
        title: 'calcium',
        description: 'formulatedRightWayToBeAbsorbedBySkin',
      },
      {
        id: 10,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
        title: 'niacinamide',
        description: 'improveSkinHydrationByPreventingEvaporationOfMoistureFromSkin',
      },
    ],
  },
  {
    id: 4,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-day-cream.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-day-cream.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/day-cream.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-day-cream.jpg',
    keyIngredientsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-day-cream.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-day-cream.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-day-cream.jpg',
    isRightInfo: true,
    isRightImage: true,
    isMask: false,
    title: 'liposomalDayCream',
    footerTitle: 'dayCream',
    navTitle: 'dayCreamAndMoisturizer',
    description: 'liposomalDayCreamDescription',
    keyIngredients: 'thisBioactiveDayCreamMoisturizerOffersLiposomes',
    productDetailsDescription: 'revolutionaryLiposomalMoisturizingDayCreamWithZeolites',
    productIngredients: [
      'reHydration',
      'collagenBoost',
      'barrierBalancing',
      'spfProtection',
      'moisturizing',
      'soothing',
    ],
    totalPrice: 99,
    priceFiat: 49.5,
    priceCrypto: 1.1647,
    quantity: 50,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Day-cream.pdf',
    dealshakerProductUrl: productUrls.dayCream,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitamins-icon.png',
        title: 'vitaminPowerhouse',
        description: 'vitaminPowerhouseBiotinPanthenolThiamineHciCyanocobalamin',
        subDescription: 'energizeSpeedsUpSkinMetabolismAndCreateYoungerFunctioningSkin',
        ingredientsList: [
          { id: 1, title: 'biotinVitaminB7', description: 'supportsHealthySkinByPromotingHydration' },
          { id: 2, title: 'panthenolVitaminB5', description: 'moisturizesSoothesAndHealsIrritatedOrDamagedSkin' },
          { id: 3, title: 'thiamineHciVitaminB1', description: 'improvesSkinTextureAndHelpsProtectSkinFromStress' },
          {
            id: 4,
            title: 'cyanocobalaminVitaminB12',
            description: 'aidsCellRegenerationAndImproveSkinToneAndBrightness',
          },
          { id: 5, title: 'riboflavinVitaminB2', description: 'maintainsMoistureLevelsSkinAndHelpsPreventDryness' },
          {
            id: 6,
            title: 'pyridoxineVitaminB6',
            description: 'helpsRegulateOilProductionAndBeneficialForAcneProneSkin',
          },
          { id: 7, title: 'folicAcidVitaminB9', description: 'promotesCellRenewalGivingSkinFresh' },
          { id: 8, title: 'menadioneVitaminK', description: 'supportsSkinHealingAndReduceAppearanceOfDarkCircles' },
          { id: 9, title: 'bioflavonoids', description: 'antioxidantsProtectSkinFromEnvironmentalDamage' },
          { id: 10, title: 'ascorbicAcidVitaminC', description: 'brightensSkinBoostsCollagenProduction' },
          {
            id: 11,
            title: 'tocopherylAcetateVitaminE',
            description: 'stableFormVitaminEMoisturizesAndProtectsSkinFromDamage',
          },
          { id: 12, title: 'glutathione', description: 'powerfulAntioxidantBrightensSkinAndReducesDarkSpots' },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/multi-moisturize.png',
        title: 'fructoseGlucoseSucrose',
        description: 'powerfulHumectantsToKeepMoistureLockedSkin',
        ingredientsList: [
          { id: 1, title: 'fructose', description: 'naturalSugarHydratesSkinAndHelpsMaintainMoistureLevels' },
          { id: 2, title: 'glucose', description: 'anotherNaturalSugarActsHumectant' },
          { id: 3, title: 'sucrose', description: 'commonlyKnownSugarHelpsToExfoliateSkinGently' },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/alanine.png',
        title: 'ureaAlanineAsparticAcidHexylNicotinate',
        description: 'humectantsMoisturizers',
        ingredientsList: [
          { id: 1, title: 'urea', description: 'powerfulHumectantHelpsToRetainMoistureSkin' },
          { id: 2, title: 'alanine', description: 'aminoAcidHelpsToHydrateSkinAndMaintain' },
          { id: 3, title: 'asparticAcid', description: 'anotherAminoAcidSupportsSkinHydration' },
          {
            id: 4,
            title: 'hexylNicotinate',
            description: 'formOfNiacinPromotesCirculationAndProvidesWarmingSensation',
          },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
        title: 'liposomeQTen',
        description: 'energizesAndAffectsBuildUpOfCollagenToReduceWrinkles',
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/probiotic.png',
        title: 'probiotic',
        description: 'lactobacillusCultureImprovesBarrierFunction',
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitaminC-icon.png',
        title: 'liposomeVitaminC',
        description: 'increasedStabilityOfVitaminCEnsuresAmazingAntioxidant',
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cinnamon-icon.png',
        title: 'liposomeCinnamon',
        description: 'powerAntioxToBalanceSkinTone',
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
        title: 'liposomeCaffeine',
        description: 'increasesPhotoProtectionAndBoostsSkinMetabolism',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/nmf-natural-moisturizing-factors.png',
        title: 'nmfNaturalMoisturizingFactors',
      },
    ],
  },
  {
    id: 5,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-night-cream.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-night-cream.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/night-cream.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-night-cream.jpg',
    keyIngredientsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-night-cream.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-night-cream.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-night-cream.jpg',
    isRightInfo: false,
    isRightImage: false,
    isMask: false,
    title: 'liposomalNightCream',
    footerTitle: 'nightCream',
    navTitle: 'nightCream',
    description: 'liposomalNightCreamDescription',
    keyIngredients: 'mostPowerfulNightComboLiposomalRetinol',
    productDetailsDescription: 'investInYourSkincareRoutineWithOurExclusiveNightCream',
    productIngredients: ['barrierBalancing', 'antiOx', 'skinBuilding', 'antiWrinkle'],
    totalPrice: 99,
    priceFiat: 49.5,
    priceCrypto: 1.1647,
    quantity: 50,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Night-cream.pdf',
    dealshakerProductUrl: productUrls.nightCream,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/amino-acids.png',
        title: 'aminoAcidsGlutamineLeucinePhenylalanineArginineLysineHclProline',
        subDescription: 'importantNutrientsRequiredForWoundHealingPromotion',
        ingredientsList: [
          { id: 1, title: 'glutamine', description: 'helpsMaintainSkinHydrationAndSupportsSkinBarrier' },
          { id: 2, title: 'leucine', description: 'essentialForProteinSynthesisAidsInRepairingAndRejuvenatingSkin' },
          { id: 3, title: 'phenylalanine', description: 'supportsSkinHealingAndHelpRegulateMelaninProduction' },
          { id: 4, title: 'arginine', description: 'promotesBloodFlowAndImprovesSkinElasticity' },
          { id: 5, title: 'lysineHci', description: 'essentialForCollagenProductionHelpsStrengthenSkin' },
          { id: 6, title: 'proline', description: 'supportsCollagenFormationEnhancingSkinStructure' },
          { id: 7, title: 'asparticAcid', description: 'helpsImproveSkinHydrationAndElasticity' },
          { id: 8, title: 'valine', description: 'essentialAminoAcidThatAidsInSkinRepair' },
          { id: 9, title: 'glycine', description: 'supportsCollagenSynthesisAndHelpsMaintainSkinHydration' },
          { id: 10, title: 'isoleucine', description: 'playsRoleSkinRepairAndHelpsToMaintainOverallSkinHealth' },
          { id: 11, title: 'threonine', description: 'supportsCollagenProductionAndHydrationEnhancingSkinTexture' },
          { id: 12, title: 'histidine', description: 'antioxidantHelpsProtectSkinFromDamage' },
          { id: 13, title: 'serine', description: 'contributesToSkinHydrationAndHelpsMaintainSkinBarrier' },
          { id: 14, title: 'alanine', description: 'aidsMoistureRetentionAndHelpsImproveSkinTextureAndSoftness' },
          { id: 15, title: 'methionine', description: 'antioxidantSupportsSkinHealthAndHelpsProtect' },
          { id: 16, title: 'tryptophan', description: 'supportsSkinRepairAndImproveOverallSkinTone' },
          { id: 17, title: 'tyrosine', description: 'helpsRegulateMelaninProduction' },
          {
            id: 18,
            title: 'cysteine',
            description: 'antioxidantSupportsCollagenProductionAndHelpsMaintainSkinElasticity',
          },
          { id: 19, title: 'taurine', description: 'helpsProtectSkinFromEnvironmentalStress' },
          { id: 20, title: 'carnitine', description: 'aidsCellularMetabolismPromotingSkinRejuvenation' },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/omega-3-6.png',
        title: 'omega36EssentialFattyAcidsLinoleicAndLinolenicAcid',
        description: 'healthySkinNeedNoSpecialExplanation',
        subDescription: 'helpPreventWaterLossAndStrengthenSkin',
        ingredientsList: [
          { id: 1, title: 'linoleicAcidOmega6', description: 'essentialFattyAcidHelpsMaintainSkinBarrierFunction' },
          {
            id: 2,
            title: 'linolenicAcidOmega3',
            description: 'anotherEssentialFattyAcidSupportsSkinHealthByReducingInflammation',
          },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/retinol-icon.png',
        title: 'liposomeRetinol',
        description: 'twoSourcesLiposomeAndMicroencapsulatedBothEnsureRetinol',
        subDescription: 'oneOfRareIngredientsDermatologyConsiders',
        ingredientsList: [
          { id: 1, title: 'liposomeRetinol', description: 'thisStableFormOfRetinolVitaminAEncapsulatedInLiposomes' },
          {
            id: 2,
            title: 'microencapsulatedRetinol',
            description: 'thisFormInvolvesEncapsulatingRetinolInMicroscopicParticlesProtect',
          },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/ceramides.png',
        title: 'ceramidesCeramide1Ceramide2Ceramide3Ceramide4Ceramide6Cholesterol',
        description: 'skinBuildersWithVerySimplePhilosophy',
        subDescription: 'buildingUpStrongSkinMatrix',
        ingredientsList: [
          { id: 1, title: 'ceramide1', description: 'helpsStrengthenSkinBarrierRetainMoisture' },
          { id: 2, title: 'ceramide2', description: 'promotesHydrationAndSupportsSkinElasticity' },
          { id: 3, title: 'ceramide3', description: 'aidsSkinBarrierRepairAndMoistureRetention' },
          { id: 4, title: 'ceramide4', description: 'enhancesSkinBarrierFunctionAndHelpsMaintainMoistureLevels' },
          { id: 5, title: 'ceramide6', description: 'supportsSkinNaturalBarrierImprovingHydration' },
          { id: 6, title: 'cholesterol', description: 'worksSynergyWithCeramidesToRestoreSkinBarrier' },
        ],
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cold-pressed-oils.png',
        title: 'coldPressedOilsWalnutApricotRedGrapeSeed',
        description: 'replaceLipidsAndAddPlumpnessToAgingSkin',
        ingredientsList: [
          { id: 1, title: 'walnutOil', description: 'richOmega3FattyAcidsAndAntioxidantsWalnutOilNourishesSkin' },
          { id: 2, title: 'apricotOil', description: 'highVitaminsAAndELightweightOilHydrates' },
          { id: 3, title: 'redGrapeSeedOil', description: 'packedWithAntioxidantsAndLinoleicAcid' },
        ],
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/bakuchiol.png',
        title: 'bakuchiolSachaInchiOilSqualane',
        description: 'richVitaminAAndOmega',
        ingredientsList: [
          { id: 1, title: 'bakuchiol', description: 'plantBasedAlternativeToRetinol' },
          { id: 2, title: 'sachaInchiOil', description: 'richOmegaFattyAcidsAntioxidantsAndVitaminsAAndE' },
          { id: 3, title: 'squalane', description: 'highlyEffectiveMoisturizerMimicsSkinNaturalOilsSqualaneHydrates' },
        ],
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sea-weeds.png',
        title: 'seaWeedsOarweedAndSingleCellGreenAlgae',
        description: 'promoteYouthfulSkinByAbundanceOfUsefulMinerals',
        subDescription: 'singleCellAlgaePromoteStrongCellularRenewal',
        ingredientsList: [
          { id: 1, title: 'oarweedLaminariaDigitata', description: 'typeOfBrownSeaweedRichVitaminsAndMinerals' },
          {
            id: 2,
            title: 'singleCellGreenAlgaeChlorella',
            description: 'packedWithNutrientsAndAntioxidantsAlgaeHelpsDetoxifySkin',
          },
        ],
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
        title: 'liposomeQTen',
        description: 'energizesSkinAndAffectsBuildUpOfCollagenToReduceWrinkles',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/liposome-melatonin.png',
        title: 'liposomeMelatonin',
        description: 'naturalRestorativeAntiOxPowerhouseToRechargeSkinRadiance',
      },
      {
        id: 10,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/rice-lipides.png',
        title: 'riceLipids',
        description: 'strongMoisturizerAndBarrierEnhancer',
      },
      {
        id: 11,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/allantoin.png',
        title: 'allantoin',
        description: 'naturallyOccurringManyPlantsAbilityToStimulateNewSkinGrowth',
      },
      {
        id: 12,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
        title: 'niacinamide',
        description: 'improveSkinHydrationByPreventingEvaporationOfMoistureFromSkin',
      },
    ],
  },
  {
    id: 6,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-calming-hydrating-mask.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-calming-hydrating-mask.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/calming-hydrating-mask.jpg',
    introducingImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-calming-hydrating-mask.jpg',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-calming-hydrating-mask.jpg',
    allProductsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-calming-hydrating-mask.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-calming-hydrating-mask.jpg',
    isRightInfo: true,
    isRightImage: true,
    isMask: true,
    title: 'liposomalCalmingAndHydratingMask',
    footerTitle: 'calmingAndHydratingMask',
    navTitle: 'calmingAndHydratingMask',
    description: 'liposomalCalmingAndHydratingMaskDescription',
    keyIngredients: 'pcaMineralsSaltsTrehaloseLiposomalQtenMushroom',
    productDetailsDescription: 'deepMoisturizingPropertiesOfThisMaskWorkFast',
    productIngredients: [
      'restoresSkinEssentialMoisture',
      'continualBurstOfHydration',
      'softensAndSmooths',
      'boostsRadiance',
      'infusedWithAntioxidants',
    ],
    totalPrice: 59,
    priceFiat: 29.5,
    priceCrypto: 0.6941,
    quantity: 4,
    numberOfQuantity: 10,
    downloadPdfLink:
      'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Calming-and-hydrating-mask.pdf',
    dealshakerProductUrl: productUrls.calmingAndHydratingMask,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/natural-anti-ox.png',
        title: 'naturalAntiOxGreenCaviarWakameLicoriceRootElderberryFruitGreenTea',
        subDescription: 'reducesMicroInflammationsAndReducesOxidativeDamage',
        ingredientsList: [
          { id: 1, title: 'greenCaviarSeaGrapes', description: 'packedWithVitaminsAndMineralsGreenCaviar' },
          {
            id: 2,
            title: 'wakameUndariaPinnatifida',
            description: 'typeOfSeaweedContainsAntioxidantsVitaminsAndMinerals',
          },
          { id: 3, title: 'licoriceRoot', description: 'knownForSoothingAndBrighteningPropertiesLicoriceRoot' },
          {
            id: 4,
            title: 'elderberryFruit',
            description: 'richVitaminsAAndCAndAntioxidantsElderberryHelpsFightOxidativeStress',
          },
          {
            id: 5,
            title: 'greenTea',
            description: 'packedWithPowerfulAntioxidantsCalledCatechinsGreenTeaHelpsProtectSkin',
          },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/antioxidants.png',
        title: 'antiOx',
        ingredientsList: [
          { id: 1, title: 'cordycepsMushroom', description: 'knownForAntioxidantPropertiesHelpsProtectSkin' },
          {
            id: 2,
            title: 'inonotusObliquusChagaMushroom',
            description: 'richAntioxidantsMushroomHelpsReduceInflammation',
          },
          {
            id: 3,
            title: 'ganodermaReishiMushroom',
            description: 'knownForSoothingAndAntiAgingPropertiesHelpsCombatFreeRadicals',
          },
          { id: 4, title: 'asparticAcid', description: 'aminoAcidSupportsSkinHydrationAndHelpImproveSkinTexture' },
          { id: 5, title: 'panthenolVitaminB5', description: 'moisturizingAgentSoothesAndHydratesSkin' },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/multi-moisturize.png',
        title: 'multiMoisturizers',
        ingredientsList: [
          { id: 1, title: 'sucrose', description: 'naturalSugarActsHumectant' },
          { id: 2, title: 'fructose', description: 'anotherNaturalSugarRetainsMoistureSkin' },
          { id: 3, title: 'glucose', description: 'naturalHumectantHelpsDrawMoistureSkin' },
          { id: 4, title: 'alanine', description: 'aminoAcidAidsMaintainingSkinMoistureLevels' },
          { id: 5, title: 'sodiumPca', description: 'naturallyOccurringComponentSkinHelpsAttractAndBindMoisture' },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/multi-moisturize.png',
        title: 'multiMoisturizersSugarsSucroseGlucoseAminoAcidsAlaninePcaSalts',
        subDescription: 'affectHydrationIsDifferentLevels',
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
        title: 'liposomeQTen',
        description: 'energizesSkinAndAffectsBuildUpOfCollagenToReduceWrinkles',
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/allantoin.png',
        title: 'allantoin',
        description: 'helpsToHydrateSkinReduceItchinessAndImproveOverallTexture',
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/probiotic.png',
        title: 'probioticLactobacillusFerment',
        description: 'repairsBarrierBalancesAndBoostsSkinNaturalProtection',
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/rice-lipides.png',
        title: 'riceLipids',
        description: 'strongMoisturizerAndBarrierEnhancer',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
        title: 'niacinamide',
        description: 'brightensAndBalances',
      },
    ],
  },
  {
    id: 7,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-metabolic-activating-mask.jpg',
    sliderImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-metabolic-activating-mask.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/metabolic-activating-mask.jpg',
    introducingImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-metabolik-activating-mask.jpg',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-metabolic-activating-mask.jpg',
    allProductsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-metabolic-activating-mask.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-metabolic-activating-mask.jpg',
    isRightInfo: false,
    isRightImage: false,
    isMask: true,
    title: 'liposomalMetabolicActivatingMask',
    footerTitle: 'metabolicActivatingMask',
    navTitle: 'metabolicActivatingMask',
    description: 'liposomalMetabolicActivatingMaskDescription',
    keyIngredients: 'lactobacillusFermentLysateFiltrateChilliPeptide',
    productDetailsDescription: 'powerfulActivatingMaskWorksToIncreaseAccelerate',
    productIngredients: [
      'acceleratesSubstanceExchange',
      'cellularEnergizing',
      'rejuvenates',
      'antiAging',
      'improvingAndAcceleratingSkinMetabolics',
    ],
    totalPrice: 59,
    priceFiat: 29.5,
    priceCrypto: 0.6941,
    quantity: 4,
    numberOfQuantity: 10,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Metabolic-mask.pdf',
    dealshakerProductUrl: productUrls.metabolicMask,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/fruit-acid-complex.png',
        title: 'fruitAcidComplexOrangeLemonSugarCaneBlueberry',
        description: 'immediatelyVisibleResultsBoostedCellularRenewalBothAffect',
        ingredientsList: [
          { id: 1, title: 'orangeExtract', description: 'richCitricAcidHelpsExfoliateSkin' },
          { id: 2, title: 'lemonExtract', description: 'containsAlphaHydroxyAcidsAHAsExfoliateDeadSkinCells' },
          { id: 3, title: 'sugarCaneExtract', description: 'naturalSourceOfGlycolicAcidGentlyExfoliatesSkin' },
          {
            id: 4,
            title: 'blueberryExtract',
            description: 'packedWithAntioxidantsHelpsProtectSkinFromEnvironmentalDamage',
          },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/tropical-fruit-enzymes.png',
        title: 'tropicalFruitEnzymes',
        description: 'enzymesWorkSynergyMildlyExfoliateAndReduceVsibleWrinkles',
        ingredientsList: [
          {
            id: 1,
            title: 'averrhoaCarambolaStarfruitFruitExtract',
            description: 'richVitaminCAntioxidantsExtractHelps',
          },
          {
            id: 2,
            title: 'passifloraIncarnataPassionfruitFruitExtract',
            description: 'packedWithVitaminsAAndCExtractNourishesSkin',
          },
          {
            id: 3,
            title: 'actinidiaChinensisKiwiFruitExtract',
            description: 'highVitaminsCAndEKiwiExtractProvidesAntioxidantBenefits',
          },
          {
            id: 4,
            title: 'garciniaMangostanaMangosteenPeelExtract',
            description: 'knownForPowerfulAntioxidantPropertiesMangosteenExtract',
          },
          {
            id: 5,
            title: 'ananasSativusPineappleFruitExtract',
            description: 'containsBromelainEnzymeGentlyExfoliatesSkin',
          },
          {
            id: 6,
            title: 'punicaGranatumPomegranateExtract',
            description: 'richAntioxidantsPomegranateExtractHelpsProtectSkinFromDamage',
          },
          {
            id: 7,
            title: 'litchiChinensisLycheeFruitExtract',
            description: 'packedWithVitaminCAndAntioxidantsLycheeExtractHelps',
          },
          {
            id: 8,
            title: 'zizyphusJujubaJujubeFruitExtract',
            description: 'knownSoothingPropertiesJujubeExtractHelps',
          },
          {
            id: 9,
            title: 'psidiumGuajavaGuavaFruitExtract',
            description: 'richVitaminsAndAntioxidantsGuavaExtractHelps',
          },
          {
            id: 10,
            title: 'leuconostocRadishRootFermentFiltrate',
            description: 'naturalFermentDerivedFromRadishRootPreservative',
          },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/olive-leaf-extract-licorice-extract-icon.png',
        title: 'naturalAntiOxOliveLeafExtractElderberryFruitExtractGotuKola',
        description: 'naturalRejuvenationSupport',
        ingredientsList: [
          { id: 1, title: 'oliveLeafExtract', description: 'richAntioxidantsOleuropeinExtractHelpsProtectSkin' },
          {
            id: 2,
            title: 'elderberryFruitExtract',
            description: 'packedWithVitaminsAAndCAndAntioxidantsElderberryHelpsFightOxidativeStress',
          },
          {
            id: 3,
            title: 'gotuKolaCentellaAsiatica',
            description: 'knownForHealingPropertiesHerbHelpsImproveCirculation',
          },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
        title: 'niacinamidePanthenol',
        description: 'brightenAndRebootSkin',
        ingredientsList: [
          {
            id: 1,
            title: 'niacinamideVitaminB3',
            description: 'knownForVersatileBenefitsNiacinamideHelpsImproveSkinTexture',
          },
          { id: 2, title: 'panthenolVitaminB5', description: 'deeplyHydratingIngredientSoothesAndSoftensSkin' },
        ],
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/minerals-rca-icon.png',
        title: 'sodiumRcaTrehalose',
        description: 'naturalMoisturizingFactorsIncreaseHydrationLevelsDermis',
        ingredientsList: [
          { id: 1, title: 'sodiumPca', description: 'naturalMoisturizingIngredientHelpsKeepSkinHydrated' },
          { id: 2, title: 'trehalose', description: 'naturalSugarActsPowerfulHumectant' },
        ],
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/white-willow-bark-extract.png',
        title: 'whiteWillowBarkExtract',
        description: 'naturalSourceOfSalicylicAcidStrongExfoliatorAndBooster',
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/probiotic.png',
        title: 'lactobacillusFermentLysateFiltrate',
        description: 'probioticBoosterForCellularRespirationCollagenProduction',
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/chili-peptides.png',
        title: 'chiliPeptides',
        description: 'affectIncreasedCellularTurnoverWhichPrecursorOfYouthfulLooks',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cinnamon-icon.png',
        title: 'liposomeCinnamon',
        description: 'strongAntioxidantWithToneEveningAndWhiteningProperties',
      },
      {
        id: 10,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/witch-hazel-water.png',
        title: 'witchHazelWater',
        description: 'reducesMicroInflammationsSkin',
      },
      {
        id: 11,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/beta-glucan.png',
        title: 'betaGlucan',
        description: 'immunoBoostSimultaneouslyCapableOfIncreasingCollagenSynthesis',
      },
    ],
  },
];

export const PRODUCTS_SET_DATA = {
  title: 'Liposomal 5-product set',
  totalPrice: 349,
  priceFiat: 174.5,
  priceCrypto: 4.1059,
};

export const ROUTINE_PRODUCTS_DATA = [
  {
    id: 1,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-cleaning-gel.svg',
    title: 'purifyAndRefresh',
    description: 'effortlesslyLiftAwayImpuritiesAndMakeup',
    buttonText: 'liposomalCleansingGel',
    isRightImage: true,
  },
  {
    id: 2,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-filler-serum.svg',
    title: 'elasticityAndSmoothness',
    description: 'powerfulElasticityBoostingIngredients',
    buttonText: 'liposomalFillerSerum',
    isRightImage: false,
  },
  {
    id: 3,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-eye-cream.svg',
    title: 'eyeRevival',
    description: 'targetedHydrationThatAddressesPuffiness',
    buttonText: 'liposomalEyeCream',
    isRightImage: true,
  },
  {
    id: 4,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-day-cream.svg',
    title: 'daytimeDefense',
    description: 'hydrateAndShieldAgainstDailyStressors',
    buttonText: 'liposomalDayCream',
    isRightImage: false,
  },
  {
    id: 5,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-night-cream.svg',
    title: 'overnightRenewal',
    description: 'enhanceYourSkinNaturalRenewalProcessOvernight',
    buttonText: 'liposomalNightCream',
    isRightImage: true,
  },
];

export const FOOTER_ALL_PRODUCTS_MOCK_DATA = [
  {
    id: 1,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-cleansing-gel.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-cleansing-gel.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cleaning-gel.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-cleansing-gel.jpg',
    keyIngredientsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-cleansing-gel.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-cleancing-gel.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-cleansing-gel.jpg',
    isRightInfo: true,
    isRightImage: false,
    isMask: false,
    title: 'liposomalCleansingGel',
    footerTitle: 'cleansingGel',
    navTitle: 'cleansingGel',
    description: 'liposomalCleansingGelDescription',
    keyIngredients: 'thisCleanserContainsOnlyNaturalActiveCleansingIngredients',
    productDetailsDescription: 'ourRevolutionaryLiposomalCleansingGel',
    productIngredients: [
      'complexCleansing',
      'lipidReplacement',
      'gentleAndMoisturizing',
      'barrierBalancing',
      'mineralEnriched',
    ],
    totalPrice: 89,
    priceFiat: 44.5,
    priceCrypto: 1.0471,
    quantity: 100,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Cleansing-gel.pdf',
    dealshakerProductUrl: productUrls.cleaningGel,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitamins-icon.png',
        title: 'vitamins',
        description: 'vitaminsDescription',
        ingredientsList: [
          { id: 1, title: 'biotin', description: 'vitaminB7HelpsMaintainHealthySkin' },
          { id: 2, title: 'panthenol', description: 'formOfVitaminB5MoisturizesSkin' },
          { id: 3, title: 'thiamineHci', description: 'vitaminB1HelpsImproveSkinTexture' },
          { id: 4, title: 'cyanocobalamin', description: 'vitaminB12HelpsCellRegenerationAndImproveSkinTone' },
          { id: 5, title: 'riboflavin', description: 'vitaminB2PromotesHealthySkinByHelping' },
          { id: 6, title: 'pyridoxine', description: 'vitaminB6HelpsRegulateSebumProduction' },
          { id: 7, title: 'folicAcid', description: 'vitaminB9PromotesCellRenewalHelpingSkinLookFresh' },
          { id: 8, title: 'menadione', description: 'syntheticFormOfVitaminKSupportsSkinHealing' },
          { id: 9, title: 'bioflavonoids', description: 'theseAreAntioxidantsThatProtectSkin' },
          { id: 10, title: 'ascorbicAcid', description: 'vitaminCBrightensSkin' },
          { id: 11, title: 'tocopherylAcetate', description: 'stableFormOfVitaminEProtectsSkin' },
          { id: 12, title: 'glutathione', description: 'powerfulAntioxidantHelpsBrightenSkin' },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/minerals-rca-icon.png',
        title: 'mineralsPca',
        description: 'mineralsPcaDescription',
        ingredientsList: [
          { id: 1, title: 'sodiumPca', description: 'naturalMoisturizingIngredientHelpsKeepSkinHydrated' },
          { id: 2, title: 'magnesiumPca', description: 'helpsToHydrateAndStrengthenSkin' },
          { id: 3, title: 'zincPca', description: 'controlsExcessOilReducesAcne' },
          { id: 4, title: 'manganesePca', description: 'providesAntioxidantProtectionAndHelpsCollagenProduction' },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/fruit-enzymes-icon.png',
        title: 'fruitEnzymes',
        description: 'fruitEnzymesDescription',
        ingredientsList: [
          {
            id: 1,
            title: 'averrhoaCarambolaStarfruitFruitExtract',
            description: 'richVitaminCAntioxidantsExtractHelps',
          },
          {
            id: 2,
            title: 'passifloraIncarnataPassionfruitFruitExtract',
            description: 'packedWithVitaminsAAndCExtractNourishesSkin',
          },
          {
            id: 3,
            title: 'actinidiaChinensisKiwiFruitExtract',
            description: 'highVitaminsCAndEKiwiExtractProvidesAntioxidantBenefits',
          },
          {
            id: 4,
            title: 'garciniaMangostanaMangosteenPeelExtract',
            description: 'knownForPowerfulAntioxidantPropertiesMangosteenExtract',
          },
          {
            id: 5,
            title: 'ananasSativusPineappleFruitExtract',
            description: 'containsBromelainEnzymeGentlyExfoliatesSkin',
          },
          { id: 6, title: 'punicaGranatumPomegranateExtract', description: 'richVitaminCAntioxidantsExtractHelps' },
          {
            id: 7,
            title: 'litchiChinensisLycheeFruitExtract',
            description: 'packedWithVitaminCAndAntioxidantsLycheeExtractHelps',
          },
          {
            id: 8,
            title: 'zizyphusJujubaJujubeFruitExtract',
            description: 'knownSoothingPropertiesJujubeExtractHelps',
          },
          {
            id: 9,
            title: 'psidiumGuajavaGuavaFruitExtract',
            description: 'richVitaminsAndAntioxidantsGuavaExtractHelps',
          },
          {
            id: 10,
            title: 'leuconostocRadishRootFermentFiltrate',
            description: 'naturalFermentDerivedFromRadishRootPreservative',
          },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/b5-aloe-vera-icon.png',
        title: 'panthenolAndAloeVera',
        description: 'panthenolAndAloeVeraDescription',
        ingredientsList: [
          { id: 1, title: 'panthenol', description: 'formOfVitaminB5DeeplyHydrates' },
          { id: 2, title: 'aloeVera', description: 'soothingPlantExtractHydratesSkin' },
        ],
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/olive-leaf-extract-licorice-extract-icon.png',
        title: 'oliveLeafExtractAndLicoriceExtract',
        description: 'oliveLeafExtractAndLicoriceExtractDescription',
        ingredientsList: [
          { id: 1, title: 'oliveLeafExtract', description: 'richAntioxidantsHelpsProtectSkinFromDamage' },
          { id: 2, title: 'licoriceExtract', description: 'helpsBrightenSkinReduceDarkSpots' },
        ],
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/glycolic-acid-icon.png',
        title: 'glycolicAcid',
        description: 'glycolicAcidDescription',
      },
    ],
  },
  {
    id: 5,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-night-cream.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-night-cream.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/night-cream.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-night-cream.jpg',
    keyIngredientsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-night-cream.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-night-cream.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-night-cream.jpg',
    isRightInfo: false,
    isRightImage: false,
    isMask: false,
    title: 'liposomalNightCream',
    footerTitle: 'nightCream',
    navTitle: 'nightCream',
    description: 'liposomalNightCreamDescription',
    keyIngredients: 'mostPowerfulNightComboLiposomalRetinol',
    productDetailsDescription: 'investInYourSkincareRoutineWithOurExclusiveNightCream',
    productIngredients: ['barrierBalancing', 'antiOx', 'skinBuilding', 'antiWrinkle'],
    totalPrice: 99,
    priceFiat: 49.5,
    priceCrypto: 1.1647,
    quantity: 50,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Night-cream.pdf',
    dealshakerProductUrl: productUrls.nightCream,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/amino-acids.png',
        title: 'aminoAcidsGlutamineLeucinePhenylalanineArginineLysineHclProline',
        subDescription: 'importantNutrientsRequiredForWoundHealingPromotion',
        ingredientsList: [
          { id: 1, title: 'glutamine', description: 'helpsMaintainSkinHydrationAndSupportsSkinBarrier' },
          { id: 2, title: 'leucine', description: 'essentialForProteinSynthesisAidsInRepairingAndRejuvenatingSkin' },
          { id: 3, title: 'phenylalanine', description: 'supportsSkinHealingAndHelpRegulateMelaninProduction' },
          { id: 4, title: 'arginine', description: 'promotesBloodFlowAndImprovesSkinElasticity' },
          { id: 5, title: 'lysineHci', description: 'essentialForCollagenProductionHelpsStrengthenSkin' },
          { id: 6, title: 'proline', description: 'supportsCollagenFormationEnhancingSkinStructure' },
          { id: 7, title: 'asparticAcid', description: 'helpsImproveSkinHydrationAndElasticity' },
          { id: 8, title: 'valine', description: 'essentialAminoAcidThatAidsInSkinRepair' },
          { id: 9, title: 'glycine', description: 'supportsCollagenSynthesisAndHelpsMaintainSkinHydration' },
          { id: 10, title: 'isoleucine', description: 'playsRoleSkinRepairAndHelpsToMaintainOverallSkinHealth' },
          { id: 11, title: 'threonine', description: 'supportsCollagenProductionAndHydrationEnhancingSkinTexture' },
          { id: 12, title: 'histidine', description: 'antioxidantHelpsProtectSkinFromDamage' },
          { id: 13, title: 'serine', description: 'contributesToSkinHydrationAndHelpsMaintainSkinBarrier' },
          { id: 14, title: 'alanine', description: 'aidsMoistureRetentionAndHelpsImproveSkinTextureAndSoftness' },
          { id: 15, title: 'methionine', description: 'antioxidantSupportsSkinHealthAndHelpsProtect' },
          { id: 16, title: 'tryptophan', description: 'supportsSkinRepairAndImproveOverallSkinTone' },
          { id: 17, title: 'tyrosine', description: 'helpsRegulateMelaninProduction' },
          {
            id: 18,
            title: 'cysteine',
            description: 'antioxidantSupportsCollagenProductionAndHelpsMaintainSkinElasticity',
          },
          { id: 19, title: 'taurine', description: 'helpsProtectSkinFromEnvironmentalStress' },
          { id: 20, title: 'carnitine', description: 'aidsCellularMetabolismPromotingSkinRejuvenation' },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/omega-3-6.png',
        title: 'omega36EssentialFattyAcidsLinoleicAndLinolenicAcid',
        description: 'healthySkinNeedNoSpecialExplanation',
        subDescription: 'helpPreventWaterLossAndStrengthenSkin',
        ingredientsList: [
          { id: 1, title: 'linoleicAcidOmega6', description: 'essentialFattyAcidHelpsMaintainSkinBarrierFunction' },
          {
            id: 2,
            title: 'linolenicAcidOmega3',
            description: 'anotherEssentialFattyAcidSupportsSkinHealthByReducingInflammation',
          },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/retinol-icon.png',
        title: 'liposomeRetinol',
        description: 'twoSourcesLiposomeAndMicroencapsulatedBothEnsureRetinol',
        subDescription: 'oneOfRareIngredientsDermatologyConsiders',
        ingredientsList: [
          { id: 1, title: 'liposomeRetinol', description: 'thisStableFormOfRetinolVitaminAEncapsulatedInLiposomes' },
          {
            id: 2,
            title: 'microencapsulatedRetinol',
            description: 'thisFormInvolvesEncapsulatingRetinolInMicroscopicParticlesProtect',
          },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/ceramides.png',
        title: 'ceramidesCeramide1Ceramide2Ceramide3Ceramide4Ceramide6Cholesterol',
        description: 'skinBuildersWithVerySimplePhilosophy',
        subDescription: 'buildingUpStrongSkinMatrix',
        ingredientsList: [
          { id: 1, title: 'ceramide1', description: 'helpsStrengthenSkinBarrierRetainMoisture' },
          { id: 2, title: 'ceramide2', description: 'promotesHydrationAndSupportsSkinElasticity' },
          { id: 3, title: 'ceramide3', description: 'aidsSkinBarrierRepairAndMoistureRetention' },
          { id: 4, title: 'ceramide4', description: 'enhancesSkinBarrierFunctionAndHelpsMaintainMoistureLevels' },
          { id: 5, title: 'ceramide6', description: 'supportsSkinNaturalBarrierImprovingHydration' },
          { id: 6, title: 'cholesterol', description: 'worksSynergyWithCeramidesToRestoreSkinBarrier' },
        ],
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cold-pressed-oils.png',
        title: 'coldPressedOilsWalnutApricotRedGrapeSeed',
        description: 'replaceLipidsAndAddPlumpnessToAgingSkin',
        ingredientsList: [
          { id: 1, title: 'walnutOil', description: 'richOmega3FattyAcidsAndAntioxidantsWalnutOilNourishesSkin' },
          { id: 2, title: 'apricotOil', description: 'highVitaminsAAndELightweightOilHydrates' },
          { id: 3, title: 'redGrapeSeedOil', description: 'packedWithAntioxidantsAndLinoleicAcid' },
        ],
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/bakuchiol.png',
        title: 'bakuchiolSachaInchiOilSqualane',
        description: 'richVitaminAAndOmega',
        ingredientsList: [
          { id: 1, title: 'bakuchiol', description: 'plantBasedAlternativeToRetinol' },
          { id: 2, title: 'sachaInchiOil', description: 'richOmegaFattyAcidsAntioxidantsAndVitaminsAAndE' },
          { id: 3, title: 'squalane', description: 'highlyEffectiveMoisturizerMimicsSkinNaturalOilsSqualaneHydrates' },
        ],
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sea-weeds.png',
        title: 'seaWeedsOarweedAndSingleCellGreenAlgae',
        description: 'promoteYouthfulSkinByAbundanceOfUsefulMinerals',
        subDescription: 'singleCellAlgaePromoteStrongCellularRenewal',
        ingredientsList: [
          { id: 1, title: 'oarweedLaminariaDigitata', description: 'typeOfBrownSeaweedRichVitaminsAndMinerals' },
          {
            id: 2,
            title: 'singleCellGreenAlgaeChlorella',
            description: 'packedWithNutrientsAndAntioxidantsAlgaeHelpsDetoxifySkin',
          },
        ],
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
        title: 'liposomeQTen',
        description: 'energizesSkinAndAffectsBuildUpOfCollagenToReduceWrinkles',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/liposome-melatonin.png',
        title: 'liposomeMelatonin',
        description: 'naturalRestorativeAntiOxPowerhouseToRechargeSkinRadiance',
      },
      {
        id: 10,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/rice-lipides.png',
        title: 'riceLipids',
        description: 'strongMoisturizerAndBarrierEnhancer',
      },
      {
        id: 11,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/allantoin.png',
        title: 'allantoin',
        description: 'naturallyOccurringManyPlantsAbilityToStimulateNewSkinGrowth',
      },
      {
        id: 12,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
        title: 'niacinamide',
        description: 'improveSkinHydrationByPreventingEvaporationOfMoistureFromSkin',
      },
    ],
  },
  {
    id: 2,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-filler-serum.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-filler-serum.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/filler-serum.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-filler-serum.jpg',
    keyIngredientsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-filler-serum.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-filler-serum.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-filler-serum.jpg',
    isRightInfo: false,
    isRightImage: true,
    isMask: false,
    title: 'liposomalFillerSerum',
    footerTitle: 'fillerSerum',
    navTitle: 'fillerSerum',
    description: 'liposomalFillerSerumDescription',
    keyIngredients: 'packedWithDifferentTypesOfCollagen',
    productDetailsDescription: 'ourLiposomalPlumpingSerumInnovativeSkincareProduct',
    productIngredients: ['plumpingEffect', 'immediateTightening', 'maskingWrinkles', 'lifting', 'hydrating'],
    totalPrice: 119,
    priceFiat: 59.5,
    priceCrypto: 1.4,
    quantity: 40,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Filler-serum.pdf',
    dealshakerProductUrl: productUrls.fillerSerum,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/peptides.png',
        title: 'peptidesAcetylHexapeptidePalmitoylTripeptide',
        description: 'neuroSignallersSignalToSpecificCellsAndOrganisms',
        subDescription: 'peptidicFormPeptidesEasierAbsorbAndGetSkinMatrix',
        ingredientsList: [
          {
            id: 1,
            title: 'acetylHexapeptide8',
            description: 'alsoKnownAsBotoxInBottlePeptideHelpsReduceAppearanceOfFineLines',
          },
          { id: 2, title: 'palmitoylTripeptide1', description: 'peptideThatStimulatesCollagenProduction' },
          { id: 3, title: 'palmitoylTetrapeptide7', description: 'reducesInflammationAndPromotesSkinHealing' },
          { id: 4, title: 'palmitoylPentapeptide4', description: 'boostsCollagenAndElastinProduction' },
          { id: 5, title: 'tripeptide29', description: 'stimulatesCollagenSynthesis' },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/natural-gums.png',
        title: 'naturalGumsAcaciaGumRhizobianGumPvp',
        description: 'fillsExistingWrinkleCavityStretchesSkin',
        ingredientsList: [
          { id: 1, title: 'acaciaGum', description: 'naturalThickenerAndEmulsifierHelpsStabilizeFormulations' },
          { id: 2, title: 'rhizobianGum', description: 'naturalPolysaccharideProvidesExcellentMoisturizingProperties' },
          { id: 3, title: 'pvpPolyvinylpyrrolidone', description: 'syntheticPolymerOftenUsedFilmFormingAgent' },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sea-weeds.png',
        title: 'seaWeedsKelpSpirulina',
        description: 'naturalTightenersWithAbundanceOfMineralsAndVitamins',
        ingredientsList: [
          { id: 1, title: 'kelp', description: 'richVitaminsAndMineralsKelpDeeplyHydratesSkin' },
          { id: 2, title: 'spirulina', description: 'blueGreenAlgaePackedWithAntioxidantsAndNutrients' },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
        title: 'liposomeCaffeine',
        description: 'optimizingCapillaryCirculationMakesSkinLookBrighter',
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/collagens.png',
        title: 'collagens11Types',
        description: 'essentialProteinForBeautifulAndTightSkin',
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/elderberry-fruit.png',
        title: 'elderberryFruit',
        description: 'powerfulAntioxidantWithDirectImpactToReducingMicroInflammation',
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/allantoin.png',
        title: 'allantoin',
        description: 'naturallyOccurringManyPlantsAbilityToStimulateNewSkinGrowth',
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
        title: 'niacinamide',
        description: 'improveSkinHydrationByPreventingEvaporationOfMoistureFromSkin',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/procollagen.png',
        title: 'procollagen',
        description: 'collagenPrecursorProvideBetterCollagenSynthesis',
      },
      {
        id: 10,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/triple-size-hyaluronoc-acid.png',
        title: 'tripleSizeHyaluronicAcid',
        description: 'hydratingThreeSkinLayers',
      },
    ],
  },
  {
    id: 6,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-calming-hydrating-mask.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-calming-hydrating-mask.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/calming-hydrating-mask.jpg',
    introducingImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-calming-hydrating-mask.jpg',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-calming-hydrating-mask.jpg',
    allProductsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-calming-hydrating-mask.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-calming-hydrating-mask.jpg',
    isRightInfo: true,
    isRightImage: true,
    isMask: true,
    title: 'liposomalCalmingAndHydratingMask',
    footerTitle: 'calmingAndHydratingMask',
    navTitle: 'calmingAndHydratingMask',
    description: 'liposomalCalmingAndHydratingMaskDescription',
    keyIngredients: 'pcaMineralsSaltsTrehaloseLiposomalQtenMushroom',
    productDetailsDescription: 'deepMoisturizingPropertiesOfThisMaskWorkFast',
    productIngredients: [
      'restoresSkinEssentialMoisture',
      'continualBurstOfHydration',
      'softensAndSmooths',
      'boostsRadiance',
      'infusedWithAntioxidants',
    ],
    totalPrice: 59,
    priceFiat: 29.5,
    priceCrypto: 0.6941,
    quantity: 4,
    numberOfQuantity: 10,
    downloadPdfLink:
      'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Calming-and-hydrating-mask.pdf',
    dealshakerProductUrl: productUrls.calmingAndHydratingMask,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/natural-anti-ox.png',
        title: 'naturalAntiOxGreenCaviarWakameLicoriceRootElderberryFruitGreenTea',
        subDescription: 'reducesMicroInflammationsAndReducesOxidativeDamage',
        ingredientsList: [
          { id: 1, title: 'greenCaviarSeaGrapes', description: 'packedWithVitaminsAndMineralsGreenCaviar' },
          {
            id: 2,
            title: 'wakameUndariaPinnatifida',
            description: 'typeOfSeaweedContainsAntioxidantsVitaminsAndMinerals',
          },
          { id: 3, title: 'licoriceRoot', description: 'knownForSoothingAndBrighteningPropertiesLicoriceRoot' },
          {
            id: 4,
            title: 'elderberryFruit',
            description: 'richVitaminsAAndCAndAntioxidantsElderberryHelpsFightOxidativeStress',
          },
          {
            id: 5,
            title: 'greenTea',
            description: 'packedWithPowerfulAntioxidantsCalledCatechinsGreenTeaHelpsProtectSkin',
          },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/antioxidants.png',
        title: 'antiOx',
        ingredientsList: [
          { id: 1, title: 'cordycepsMushroom', description: 'knownForAntioxidantPropertiesHelpsProtectSkin' },
          {
            id: 2,
            title: 'inonotusObliquusChagaMushroom',
            description: 'richAntioxidantsMushroomHelpsReduceInflammation',
          },
          {
            id: 3,
            title: 'ganodermaReishiMushroom',
            description: 'knownForSoothingAndAntiAgingPropertiesHelpsCombatFreeRadicals',
          },
          { id: 4, title: 'asparticAcid', description: 'aminoAcidSupportsSkinHydrationAndHelpImproveSkinTexture' },
          { id: 5, title: 'panthenolVitaminB5', description: 'moisturizingAgentSoothesAndHydratesSkin' },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/multi-moisturize.png',
        title: 'multiMoisturizers',
        ingredientsList: [
          { id: 1, title: 'sucrose', description: 'naturalSugarActsHumectant' },
          { id: 2, title: 'fructose', description: 'anotherNaturalSugarRetainsMoistureSkin' },
          { id: 3, title: 'glucose', description: 'naturalHumectantHelpsDrawMoistureSkin' },
          { id: 4, title: 'alanine', description: 'aminoAcidAidsMaintainingSkinMoistureLevels' },
          { id: 5, title: 'sodiumPca', description: 'naturallyOccurringComponentSkinHelpsAttractAndBindMoisture' },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/multi-moisturize.png',
        title: 'multiMoisturizersSugarsSucroseGlucoseAminoAcidsAlaninePcaSalts',
        subDescription: 'affectHydrationIsDifferentLevels',
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
        title: 'liposomeQTen',
        description: 'energizesSkinAndAffectsBuildUpOfCollagenToReduceWrinkles',
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/allantoin.png',
        title: 'allantoin',
        description: 'helpsToHydrateSkinReduceItchinessAndImproveOverallTexture',
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/probiotic.png',
        title: 'probioticLactobacillusFerment',
        description: 'repairsBarrierBalancesAndBoostsSkinNaturalProtection',
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/rice-lipides.png',
        title: 'riceLipids',
        description: 'strongMoisturizerAndBarrierEnhancer',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
        title: 'niacinamide',
        description: 'brightensAndBalances',
      },
    ],
  },
  {
    id: 3,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-eye-cream.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-eye-cream.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/eye-cream.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-eye-cream.jpg',
    keyIngredientsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-eye-cream.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-eye-cream.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-eye-cream.jpg',
    isRightInfo: true,
    isRightImage: false,
    isMask: false,
    title: 'liposomalEyeCream',
    footerTitle: 'eyeCream',
    navTitle: 'eyeContouringCream',
    description: 'liposomalEyeCreamDescription',
    keyIngredients: 'packedWithLiposomeLoadedQtenAndCaffeine',
    productDetailsDescription: 'yourEyesDeserveOnlyBestOurExtraordinaryLiposomal',
    productIngredients: ['brightening', 'immediateTightening', 'plumpingAndLifting', 'antiDarkCircle', 'hydrating'],
    totalPrice: 89,
    priceFiat: 44.5,
    priceCrypto: 1.0471,
    quantity: 25,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Eye-Contouring.pdf',
    dealshakerProductUrl: productUrls.eyeCream,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitaminC-icon.png',
        title: 'vitaminCCocktailAscorbicAcidRosehipCo2ExtractSodiumAscorbylSulphate',
        description: 'topicalVitaminCScienceBackedDermatologistsAllTimeFavorite',
        ingredientsList: [
          { id: 1, title: 'ascorbicAcid', description: 'purestFormOfVitaminCBrightensSkinBoostsCollagenProduction' },
          {
            id: 2,
            title: 'rosehipCo2Extract',
            description: 'richAntioxidantsAndEssentialFattyAcidsExtractHelpsImproveSkinTexture',
          },
          {
            id: 3,
            title: 'sodiumAscorbylSulfate',
            description: 'stableFormOfVitaminCHelpsBrightenSkinReduceDarkSpots',
          },
          {
            id: 4,
            title: 'microencapsulatedVitaminC',
            description: 'encapsulatedVitaminCReleasesGraduallyProvidingLongLastingBrightness',
          },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/peptides.png',
        title: 'peptidesAcetylHexapeptide8AargirelinePalmitoylTripeptide1',
        description: 'chosenPreciselyForSignalingAntiWrinkleTighteningAndBrighteningEffects',
        subDescription: 'transformSkinContoursLongTermAndHelpSkin',
        ingredientsList: [
          {
            id: 1,
            title: 'acetylHexapeptide8Argireline',
            description: 'oftenReferredToBotoxInBottlePeptideHelpsRelaxFacialMuscles',
          },
          {
            id: 2,
            title: 'palmitoylTripeptide1',
            description: 'stimulatesCollagenProductionAndHelpsImproveSkinFirmness',
          },
          { id: 3, title: 'palmitoylTetrapeptide7', description: 'soothesInflammationAndPromotesSkinHealing' },
          { id: 4, title: 'palmitoylPentapeptide4', description: 'boostsCollagenAndElastinSynthesis' },
          { id: 5, title: 'dipeptide2', description: 'reducesPuffinessAndDarkCirclesUnderEyes' },
          {
            id: 6,
            title: 'palmitoylTetrapeptide3',
            description: 'enhancesSkinElasticityAndReducesAppearanceOfFineLines',
          },
          { id: 7, title: 'carnosine', description: 'antioxidantPeptideProtectsAgainstOxidativeStress' },
          { id: 8, title: 'tripeptide29', description: 'supportsCollagenSynthesisLeadingToSmoother' },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/antioxidants.png',
        title: 'antioxidantsCurcumaCo2LicoriceGreenTea',
        description: 'powerfulBotanicalAntiOxToHelpBrightenDarkCircles',
        ingredientsList: [
          { id: 1, title: 'curcumaCo2TurmericExtract', description: 'richInCurcuminHasPotentAntiInflammatory' },
          { id: 2, title: 'licorice', description: 'knownForSoothingPropertiesHelpsBrightenSkin' },
          { id: 3, title: 'greenTea', description: 'packedWithAntioxidantsProtectsSkinFromEnvironmentalDamage' },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/botanical-oils.png',
        title: 'botanicalOilsAbbysinianRedGrapeSeedSqualaneApricot',
        description: 'plumpersAndNutrientsWithBotanicalBackground',
        ingredientsList: [
          {
            id: 1,
            title: 'abyssinianOil',
            description: 'derivedFromAbyssinianPlantLightweightOilRichOmega9FattyAcids',
          },
          { id: 2, title: 'redGrapeSeedOil', description: 'highAntioxidantsAndLinoleicAcidHelpsProtectSkin' },
          { id: 3, title: 'squalane', description: 'highlyEffectiveMoisturizerMimicsSkinNaturalOils' },
          { id: 4, title: 'apricotOil', description: 'richVitaminsAAndENourishesAndHydratesSkin' },
        ],
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/triple-size-hyaluronoc-acid.png',
        title: 'tripleSizeHyaluronicAcid',
        description: 'glueHoldsCollagenSkinTogether',
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/beta-carotene.png',
        title: 'carrotCo2ExtractBetaCarotene',
        description: 'richestSourceOfNaturalVitaminA',
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
        title: 'liposomeCaffeine',
        description: 'byOptimizingCapillaryCirculationSkinMakesSkinLookBrighter',
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
        title: 'liposomeQTen',
        description: 'energizesSkinAndAffectsBuildUpOfCollagenToReduceWrinkles',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/calcium.png',
        title: 'calcium',
        description: 'formulatedRightWayToBeAbsorbedBySkin',
      },
      {
        id: 10,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
        title: 'niacinamide',
        description: 'improveSkinHydrationByPreventingEvaporationOfMoistureFromSkin',
      },
    ],
  },
  {
    id: 7,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-metabolic-activating-mask.jpg',
    sliderImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-metabolic-activating-mask.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/metabolic-activating-mask.jpg',
    introducingImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-metabolik-activating-mask.jpg',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-metabolic-activating-mask.jpg',
    allProductsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-metabolic-activating-mask.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-metabolic-activating-mask.jpg',
    isRightInfo: false,
    isRightImage: false,
    isMask: true,
    title: 'liposomalMetabolicActivatingMask',
    footerTitle: 'metabolicActivatingMask',
    navTitle: 'metabolicActivatingMask',
    description: 'liposomalMetabolicActivatingMaskDescription',
    keyIngredients: 'lactobacillusFermentLysateFiltrateChilliPeptide',
    productDetailsDescription: 'powerfulActivatingMaskWorksToIncreaseAccelerate',
    productIngredients: [
      'acceleratesSubstanceExchange',
      'cellularEnergizing',
      'rejuvenates',
      'antiAging',
      'improvingAndAcceleratingSkinMetabolics',
    ],
    totalPrice: 59,
    priceFiat: 29.5,
    priceCrypto: 0.6941,
    quantity: 4,
    numberOfQuantity: 10,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Metabolic-mask.pdf',
    dealshakerProductUrl: productUrls.metabolicMask,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/fruit-acid-complex.png',
        title: 'fruitAcidComplexOrangeLemonSugarCaneBlueberry',
        description: 'immediatelyVisibleResultsBoostedCellularRenewalBothAffect',
        ingredientsList: [
          { id: 1, title: 'orangeExtract', description: 'richCitricAcidHelpsExfoliateSkin' },
          { id: 2, title: 'lemonExtract', description: 'containsAlphaHydroxyAcidsAHAsExfoliateDeadSkinCells' },
          { id: 3, title: 'sugarCaneExtract', description: 'naturalSourceOfGlycolicAcidGentlyExfoliatesSkin' },
          {
            id: 4,
            title: 'blueberryExtract',
            description: 'packedWithAntioxidantsHelpsProtectSkinFromEnvironmentalDamage',
          },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/tropical-fruit-enzymes.png',
        title: 'tropicalFruitEnzymes',
        description: 'enzymesWorkSynergyMildlyExfoliateAndReduceVsibleWrinkles',
        ingredientsList: [
          {
            id: 1,
            title: 'averrhoaCarambolaStarfruitFruitExtract',
            description: 'richVitaminCAntioxidantsExtractHelps',
          },
          {
            id: 2,
            title: 'passifloraIncarnataPassionfruitFruitExtract',
            description: 'packedWithVitaminsAAndCExtractNourishesSkin',
          },
          {
            id: 3,
            title: 'actinidiaChinensisKiwiFruitExtract',
            description: 'highVitaminsCAndEKiwiExtractProvidesAntioxidantBenefits',
          },
          {
            id: 4,
            title: 'garciniaMangostanaMangosteenPeelExtract',
            description: 'knownForPowerfulAntioxidantPropertiesMangosteenExtract',
          },
          {
            id: 5,
            title: 'ananasSativusPineappleFruitExtract',
            description: 'containsBromelainEnzymeGentlyExfoliatesSkin',
          },
          {
            id: 6,
            title: 'punicaGranatumPomegranateExtract',
            description: 'richAntioxidantsPomegranateExtractHelpsProtectSkinFromDamage',
          },
          {
            id: 7,
            title: 'litchiChinensisLycheeFruitExtract',
            description: 'packedWithVitaminCAndAntioxidantsLycheeExtractHelps',
          },
          {
            id: 8,
            title: 'zizyphusJujubaJujubeFruitExtract',
            description: 'knownSoothingPropertiesJujubeExtractHelps',
          },
          {
            id: 9,
            title: 'psidiumGuajavaGuavaFruitExtract',
            description: 'richVitaminsAndAntioxidantsGuavaExtractHelps',
          },
          {
            id: 10,
            title: 'leuconostocRadishRootFermentFiltrate',
            description: 'naturalFermentDerivedFromRadishRootPreservative',
          },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/olive-leaf-extract-licorice-extract-icon.png',
        title: 'naturalAntiOxOliveLeafExtractElderberryFruitExtractGotuKola',
        description: 'naturalRejuvenationSupport',
        ingredientsList: [
          { id: 1, title: 'oliveLeafExtract', description: 'richAntioxidantsOleuropeinExtractHelpsProtectSkin' },
          {
            id: 2,
            title: 'elderberryFruitExtract',
            description: 'packedWithVitaminsAAndCAndAntioxidantsElderberryHelpsFightOxidativeStress',
          },
          {
            id: 3,
            title: 'gotuKolaCentellaAsiatica',
            description: 'knownForHealingPropertiesHerbHelpsImproveCirculation',
          },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
        title: 'niacinamidePanthenol',
        description: 'brightenAndRebootSkin',
        ingredientsList: [
          {
            id: 1,
            title: 'niacinamideVitaminB3',
            description: 'knownForVersatileBenefitsNiacinamideHelpsImproveSkinTexture',
          },
          { id: 2, title: 'panthenolVitaminB5', description: 'deeplyHydratingIngredientSoothesAndSoftensSkin' },
        ],
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/minerals-rca-icon.png',
        title: 'sodiumRcaTrehalose',
        description: 'naturalMoisturizingFactorsIncreaseHydrationLevelsDermis',
        ingredientsList: [
          { id: 1, title: 'sodiumPca', description: 'naturalMoisturizingIngredientHelpsKeepSkinHydrated' },
          { id: 2, title: 'trehalose', description: 'naturalSugarActsPowerfulHumectant' },
        ],
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/white-willow-bark-extract.png',
        title: 'whiteWillowBarkExtract',
        description: 'naturalSourceOfSalicylicAcidStrongExfoliatorAndBooster',
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/probiotic.png',
        title: 'lactobacillusFermentLysateFiltrate',
        description: 'probioticBoosterForCellularRespirationCollagenProduction',
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/chili-peptides.png',
        title: 'chiliPeptides',
        description: 'affectIncreasedCellularTurnoverWhichPrecursorOfYouthfulLooks',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cinnamon-icon.png',
        title: 'liposomeCinnamon',
        description: 'strongAntioxidantWithToneEveningAndWhiteningProperties',
      },
      {
        id: 10,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/witch-hazel-water.png',
        title: 'witchHazelWater',
        description: 'reducesMicroInflammationsSkin',
      },
      {
        id: 11,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/beta-glucan.png',
        title: 'betaGlucan',
        description: 'immunoBoostSimultaneouslyCapableOfIncreasingCollagenSynthesis',
      },
    ],
  },
  {
    id: 4,
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-day-cream.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/gel-products-day-cream.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/day-cream.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/stones-products-day-cream.jpg',
    keyIngredientsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sand-products-day-cream.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-day-cream.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-day-cream.jpg',
    isRightInfo: true,
    isRightImage: true,
    isMask: false,
    title: 'liposomalDayCream',
    footerTitle: 'dayCream',
    navTitle: 'dayCreamAndMoisturizer',
    description: 'liposomalDayCreamDescription',
    keyIngredients: 'thisBioactiveDayCreamMoisturizerOffersLiposomes',
    productDetailsDescription: 'revolutionaryLiposomalMoisturizingDayCreamWithZeolites',
    productIngredients: [
      'reHydration',
      'collagenBoost',
      'barrierBalancing',
      'spfProtection',
      'moisturizing',
      'soothing',
    ],
    totalPrice: 99,
    priceFiat: 49.5,
    priceCrypto: 1.1647,
    quantity: 50,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Day-cream.pdf',
    dealshakerProductUrl: productUrls.dayCream,
    ingredients: [
      {
        id: 1,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitamins-icon.png',
        title: 'vitaminPowerhouse',
        description: 'vitaminPowerhouseBiotinPanthenolThiamineHciCyanocobalamin',
        subDescription: 'energizeSpeedsUpSkinMetabolismAndCreateYoungerFunctioningSkin',
        ingredientsList: [
          { id: 1, title: 'biotinVitaminB7', description: 'supportsHealthySkinByPromotingHydration' },
          { id: 2, title: 'panthenolVitaminB5', description: 'moisturizesSoothesAndHealsIrritatedOrDamagedSkin' },
          { id: 3, title: 'thiamineHciVitaminB1', description: 'improvesSkinTextureAndHelpsProtectSkinFromStress' },
          {
            id: 4,
            title: 'cyanocobalaminVitaminB12',
            description: 'aidsCellRegenerationAndImproveSkinToneAndBrightness',
          },
          { id: 5, title: 'riboflavinVitaminB2', description: 'maintainsMoistureLevelsSkinAndHelpsPreventDryness' },
          {
            id: 6,
            title: 'pyridoxineVitaminB6',
            description: 'helpsRegulateOilProductionAndBeneficialForAcneProneSkin',
          },
          { id: 7, title: 'folicAcidVitaminB9', description: 'promotesCellRenewalGivingSkinFresh' },
          { id: 8, title: 'menadioneVitaminK', description: 'supportsSkinHealingAndReduceAppearanceOfDarkCircles' },
          { id: 9, title: 'bioflavonoids', description: 'antioxidantsProtectSkinFromEnvironmentalDamage' },
          { id: 10, title: 'ascorbicAcidVitaminC', description: 'brightensSkinBoostsCollagenProduction' },
          {
            id: 11,
            title: 'tocopherylAcetateVitaminE',
            description: 'stableFormVitaminEMoisturizesAndProtectsSkinFromDamage',
          },
          { id: 12, title: 'glutathione', description: 'powerfulAntioxidantBrightensSkinAndReducesDarkSpots' },
        ],
      },
      {
        id: 2,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/multi-moisturize.png',
        title: 'fructoseGlucoseSucrose',
        description: 'powerfulHumectantsToKeepMoistureLockedSkin',
        ingredientsList: [
          { id: 1, title: 'fructose', description: 'naturalSugarHydratesSkinAndHelpsMaintainMoistureLevels' },
          { id: 2, title: 'glucose', description: 'anotherNaturalSugarActsHumectant' },
          { id: 3, title: 'sucrose', description: 'commonlyKnownSugarHelpsToExfoliateSkinGently' },
        ],
      },
      {
        id: 3,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/alanine.png',
        title: 'ureaAlanineAsparticAcidHexylNicotinate',
        description: 'humectantsMoisturizers',
        ingredientsList: [
          { id: 1, title: 'urea', description: 'powerfulHumectantHelpsToRetainMoistureSkin' },
          { id: 2, title: 'alanine', description: 'aminoAcidHelpsToHydrateSkinAndMaintain' },
          { id: 3, title: 'asparticAcid', description: 'anotherAminoAcidSupportsSkinHydration' },
          {
            id: 4,
            title: 'hexylNicotinate',
            description: 'formOfNiacinPromotesCirculationAndProvidesWarmingSensation',
          },
        ],
      },
      {
        id: 4,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
        title: 'liposomeQTen',
        description: 'energizesAndAffectsBuildUpOfCollagenToReduceWrinkles',
      },
      {
        id: 5,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/probiotic.png',
        title: 'probiotic',
        description: 'lactobacillusCultureImprovesBarrierFunction',
      },
      {
        id: 6,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitaminC-icon.png',
        title: 'liposomeVitaminC',
        description: 'increasedStabilityOfVitaminCEnsuresAmazingAntioxidant',
      },
      {
        id: 7,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cinnamon-icon.png',
        title: 'liposomeCinnamon',
        description: 'powerAntioxToBalanceSkinTone',
      },
      {
        id: 8,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
        title: 'liposomeCaffeine',
        description: 'increasesPhotoProtectionAndBoostsSkinMetabolism',
      },
      {
        id: 9,
        icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/nmf-natural-moisturizing-factors.png',
        title: 'nmfNaturalMoisturizingFactors',
      },
    ],
  },
];
